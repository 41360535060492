import HorizontalProgressList from '../ProgressList/HorizontalProgressList';
import { FormProps } from './IForm';

const Form = (props: FormProps) => {
  const { children, title, progress, formStyle = 'default', ...rest } = props;
  const currentStep = progress ? progress.currentStep + 1 : 0;
  const percent = progress && Object.keys(progress).length > 0 ? (currentStep / progress.steps.length) * 100 : 0;
  const stepMsg = `Step ${currentStep} of ${progress?.steps?.length}`;
  return formStyle === 'default' ? (
    <div {...rest}>
      <div className="flex justify-center">
        <div className="w-full mt-8 md:mt-0">
          {progress && <HorizontalProgressList data={progress} />}
          <div className="flex justify-center w-full mt-4">
            <div className="max-w-2xl w-full flex justify-start">
              <h1 className="font-black pt-4 md:pt-0">{title}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        {children}
      </div>
    </div>
  ) : (
    <div {...rest}>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <h2 className="text-[32px] md:text-[48px] font-extrabold pt-4 md:pt-8 md:leading-[4rem]">{title}</h2>
        </div>
      </div>
      <div className="pt-4">
        {children}
      </div>
    </div>
  );
};

export default Form;
