import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import { useContext, useState } from 'react';
import Form from '../../../components/Form/Form';
import { IAdditionalInfo } from '../../../config/util/interfaces';
import { HelocContext } from '../HelocPage';

interface IOptionsProps {
  additionalInfo: IAdditionalInfo;
  setAdditionalInfo: any;
  fieldName: string;
  pageName: string;
  handleBack?: () => void;
}

export const HelocAdditionalOptionPage = (props: IOptionsProps) => {
  const { progress, content, isLite } = useContext(HelocContext);
  const [loading, setLoading] = useState(false);

  const options = (
    props.pageName === 'marital'
      ? content.marital_radio_button
      : content.language_radio_button
  ).map((item: any) => ({
    name: 'options',
    label: item.option.label,
    value: item.option.value,
    active: false,
  }));

  // save the marital data to the global additional info object and move to the next screen
  const handleSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    props.setAdditionalInfo(
      { ...props.additionalInfo, [props.fieldName]: event.target.value },
      props.pageName === 'marital' ? 'language' : isLite ? 'partnership' : 'Gender_Page'
    );
    setLoading(false);
  };

  return (
    <Form
      title={content[props.pageName]?.header}
      progress={progress}
    >
      {loading ? (
        <div className="min-h-[420px] flex items-center justify-center">
          <Loader color="#D13239" className="loader-medium" />
        </div>
      ) : (
        <div className="w-full">
          <div className="flex justify-center w-full">
            <div className="w-full max-w-md">
              <RadioButtons
                className="radioButton"
                radios={options}
                onChange={handleSelect}
              />
            </div>
          </div>
          <div
            className="flex justify-center -ml-8 md:block md:ml-0 mt-8"
          >
            {(isLite || props.pageName !== 'marital') && (
              <Button
                buttonStyle="quaternary"
                iconPos="left"
                iconName="chevron-left-large"
                onClick={props.handleBack}
              >
                Back
              </Button>
            )}
          </div>
        </div>
      )}
    </Form>
  );
};
