import { useContext, useState } from 'react';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Form from '../../../components/Form/Form';
import { IPricingData, IPropertyResponse } from '../../../config/util/interfaces';
import { localeOptions } from '../../../config/util/common';
import { HelocContext } from '../HelocPage';

interface ILoanSelectedProps {
  propertyData: IPropertyResponse;
  pricingData: IPricingData;
}

export const HelocLoanSelectedPage = (props: ILoanSelectedProps) => {
  const { progress, handleBackSubmit, pageName, handlePageSubmit, content } = useContext(HelocContext);
  const [loading, setLoading] = useState(false);
  const loanAmount = props.propertyData.requestedLoanAmount.toLocaleString('en-US', localeOptions);
  const monthlyPayment = props.pricingData?.selected?.term?.estimatedMonthlyPayment.toLocaleString('en-US', localeOptions) || '';
  const handleSubmit = async () => {
    setLoading(true);
    const pageData = `{ 
        page: "${pageName}"
      }`;
    await handlePageSubmit(pageData);
    setLoading(false);
  };

  return (
    <Form title={content.header} progress={progress}>
      <p>{content.description}</p>
      <h2 className="font-bold mt-4 mb-4">{content.details.header}</h2>
      <h4>{content.details.amount_label}: <span className="font-bold">{loanAmount}</span></h4>
      <h4>{content.details.term_label}: <span className="font-bold">{props.pricingData?.selected?.term?.term || ''}</span></h4>
      <h4>{content.details.monthly_payment_label}: <span className="font-bold">{monthlyPayment}</span></h4>
      <h4>{content.details.apr_label}: <span className="font-bold">{`${props.pricingData?.selected?.term?.apr || ''}%`}</span></h4>
      <div className="line mt-4 mb-4" />
      <h2 className="font-bold">{content.closing_conditions_header}</h2>
      { content.sections.map((section: any, index: number) => (
        <div key={`section${index}`} className="mt-4">
          <h2 className="font-bold text-xl">{section.section.header}</h2>
          <p>{section.section.description}</p>
        </div>
      ))}
      <div className="flex justify-center mt-12">
        <Button
          buttonStyle="primary"
          className="!w-full md:!w-48 launchDisclosures"
          onClick={handleSubmit}
          loading={loading}
          buttonAttrs={{ disabled: loading }}
        >Next
        </Button>
      </div>
      <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
        <Button
          buttonStyle="quaternary"
          iconPos="left"
          iconName="chevron-left-large"
          onClick={() => handleBackSubmit(pageName)}
          buttonAttrs={{ disabled: loading }}
        >Back
        </Button>
      </div>
    </Form>
  );
};
