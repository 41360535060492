import { useContext, useEffect, useState } from 'react';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import { IAdditionalInfo } from '../../../config/util/interfaces';
import { HelocAdditionalOptionPage } from './HelocAdditionalOptionPage';
import { HelocAdditionalQuestions } from './HelocAdditionalQuestions';
import { HelocFinalQuestions } from '../HelocFinalQuestions/HelocFinalQuestions';
import { HelocOwnershipPage } from './HelocOwnershipPage';
import Form from '../../../components/Form/Form';
import { HelocContext } from '../HelocPage';

export const HelocAdditionalQuestionsPage = () => {
  const { progress, handleBackSubmit, pageName, content, isLite } = useContext(HelocContext);
  const [loading, setLoading] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(newAdditionalInfo());
  const [questionPage, setQuestionPage] = useState(additionalInfo.maritalStatus ? pageName : 'marital');

  useEffect(() => {
    if (window.location.href.indexOf(questionPage) === -1) {
      window.history.replaceState(questionPage, 'Title', `/heloc/${isLite ? 'lite/' : ''}${questionPage}${window.location.search}`);
    }
  }, [questionPage]);

  const radioButtonMapping: any = Object.freeze({
    Gender_Page: content.gender_radio_button,
    Hispanic_Page: content.hispanic_radio_button,
    Hispanic_Detail_Page: content.hispanic_details_radio_button,
    Race_Page: content.race_radio_button,
    Islander_Page: content.islander_radio_button,
    Asian_Page: content.asian_radio_button,
  });
  // save the marital data to the global additional info object and move to the next screen
  const handleSelect = async (
    additionalInfo: IAdditionalInfo,
    page = ''
  ) => {
    setAdditionalInfo(additionalInfo);
    if (page) {
      setQuestionPage(page);
    } else {
      setQuestionPage('finalQuestions');
    }
  };

  // go to previous screen
  const handleBack = async () => {
    let page = '';
    switch (questionPage) {
    case 'finalQuestions':
      page = 'Race_Page';
      break;
    case 'Islander_Page':
    case 'Asian_Page':
      page = 'Race_Page';
      break;
    case 'Hispanic_Detail_Page':
      page = 'Hispanic_Page';
      break;
    case 'Race_Page':
      page = 'Hispanic_Page';
      break;
    case 'Hispanic_Page':
      page = 'Gender_Page';
      break;
    case 'Gender_Page':
      page = isLite ? 'partnership' : 'language';
      break;
    case 'language':
      page = 'marital';
      break;
    case 'partnership':
      page = 'language';
      break;
    case 'marital':
      page = 'marital';
      await handleBackSubmit(pageName);
      break;
    default:
      break;
    }

    setQuestionPage(page);
  };

  const commonProps = {
    setAdditionalInfo: handleSelect,
    additionalInfo,
    handleBack
  };

  if (loading) {
    return pageLoader(content, progress, questionPage);
  }

  if (questionPage === 'marital') {
    return (
      <HelocAdditionalOptionPage
        {...commonProps}
        pageName={questionPage}
        fieldName="maritalStatus"
      />
    );
  }

  if (questionPage === 'language') {
    return (
      <HelocAdditionalOptionPage
        {...commonProps}
        pageName={questionPage}
        fieldName="language"
      />
    );
  }

  if (questionPage === 'partnership' && isLite) {
    return (
      <HelocOwnershipPage
        {...commonProps}
        handleSubmit={() => setQuestionPage('Gender_Page')}
        loading={loading}
        setLoading={setLoading}
        setAdditionalInfo={setAdditionalInfo}
        handleBackSubmit={handleBack}
        additionalInfo={additionalInfo}
      />
    );
  }

  if (
    [
      'Gender_Page',
      'Hispanic_Page',
      'Hispanic_Detail_Page',
      'Race_Page',
      'Islander_Page',
      'Asian_Page',
    ].includes(questionPage)
  ) {
    return (
      <HelocAdditionalQuestions
        {...commonProps}
        pageName={questionPage}
        options={radioButtonMapping[questionPage]}
      />
    );
  }

  return <HelocFinalQuestions {...commonProps} />;
};

const pageLoader = (content: any, progress: any, questionPage: string) => (
  <Form title={content[questionPage]?.header} progress={progress}>
    <div className="min-h-[420px] flex items-center justify-center">
      <Loader color="#D13239" className="loader-medium" />
    </div>
  </Form>
);

// default values used for additional info screen
const newAdditionalInfo = () =>
  ({
    maritalStatus: '',
    ownershipType: '',
    trustName: '', // optional
    gender: '',
    ethnicity: '',
    ethnicityDetail: '', // optional
    race: '',
    asianDetail: '', // optional
    islanderDetail: '', // optional
    citizenStatus: '',
    language: '',
  } as unknown as IAdditionalInfo);
