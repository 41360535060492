import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import { useContext, useState } from 'react';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import Form from '../../../components/Form/Form';
import { IAdditionalInfo } from '../../../config/util/interfaces';
import { HelocContext } from '../HelocPage';

interface IQuestionProps {
  additionalInfo: IAdditionalInfo;
  setAdditionalInfo: any;
  options: Array<any>;
  pageName: string;
  handleBack: () => void;
}

interface IAdditionalInfoForm {
  gender: string;
  ethnicity: string;
  race: string;
}

export const HelocAdditionalQuestions = (props: IQuestionProps) => {
  const { progress, content } = useContext(HelocContext);
  const [loading] = useState(false);
  const [helocForm, setHelocForm] = useState({
    gender: '',
    ethnicity: '',
    race: ''
  } as IAdditionalInfoForm);
  const [other, setOther] = useState('');

  // renders the options for the screen based on what page you are on
  const options = props.options.map((item: any) => ({
    name: 'options',
    label: item.option.label,
    value: item.option.value,
    active: false
  }));

  // save the value to the correct field based on what page you are on.
  // navigate to the next page based on the value selected.
  const handleSelect = async (value: string) => {
    let page = '';

    if (value.length > 0) {
      switch (props.pageName) {
      case 'Gender_Page':
        props.setAdditionalInfo({ ...props.additionalInfo, gender: value }, 'Hispanic_Page');
        setHelocForm({ ...helocForm, gender: value });
        break;
      case 'Hispanic_Page':
        if (value === 'HISPANIC') {
          props.setAdditionalInfo({ ...props.additionalInfo, ethnicity: value }, 'Hispanic_Detail_Page');
        } else {
          props.setAdditionalInfo({ ...props.additionalInfo, ethnicity: value }, 'Race_Page');
          setHelocForm({ ...helocForm, ethnicity: value });
        }
        break;
      case 'Hispanic_Detail_Page':
        props.setAdditionalInfo({ ...props.additionalInfo, ethnicityDetail: value }, 'Race_Page');
        setHelocForm({ ...helocForm, ethnicity: value });
        break;
      case 'Race_Page':
        page = '';
        if (value === 'HAWAIIAN_ISLANDER') {
          page = 'Islander_Page';
        } else if (value === 'ASIAN') {
          page = ('Asian_Page');
        } else {
          setHelocForm({ ...helocForm, race: value });
        }
        props.setAdditionalInfo({ ...props.additionalInfo, race: value }, page);
        break;
      case 'Asian_Page':
        props.setAdditionalInfo({ ...props.additionalInfo, asianDetail: value });
        setHelocForm({ ...helocForm, race: value });
        break;
      case 'Islander_Page':
        props.setAdditionalInfo({ ...props.additionalInfo, islanderDetail: value });
        setHelocForm({ ...helocForm, race: value });
        break;
      default:
        break;
      }
      setOther('');
    }
  };

  return (
    <Form title={content.header} progress={progress}>
      { loading
        ? (
          <div className="min-h-[420px] flex items-center justify-center">
            <Loader color="#D13239" className="loader-medium" />
          </div>
        )
        : (
          <div>
            <div className="min-h-[300px]">
              <h4>{content.prompt}</h4>
              <h4 className="font-bold mt-4 mb-4">Select one of the following:</h4>
              <div className="mb-12">
                <div className="flex justify-center w-full">
                  <div className="w-full max-w-md">
                    <RadioButtons className="radioButton" radios={options} onChange={(event) => handleSelect(event.target.value)} />
                  </div>
                </div>
              </div>
              {options.find((option) => option.value === 'OTHER')
          && (
            <div>
              <h4 className="font-bold -mt-12 mb-4">Or</h4>
              <TextInput
                name="other"
                value={other}
                label="Other"
                onChange={(event) => setOther(event.target.value)}
              />
              <div className="flex justify-center mt-4">
                <Button
                  name="nextButton"
                  buttonStyle="primary"
                  className="!w-full md:!w-48 nextButton"
                  onClick={() => handleSelect(other)}
                  buttonAttrs={{ disabled: other.length === 0 }}
                >Next
                </Button>
              </div>
            </div>
          )}
            </div>
            <div className="flex justify-center mt-8 -ml-8 mb-4 md:block md:mb-8 md:ml-0">
              <Button buttonStyle="quaternary" iconPos="left" iconName="chevron-left-large" onClick={props.handleBack}>Back</Button>
            </div>
            <p className="text-sm">{content.disclaimer}</p>
          </div>
        )}
    </Form>
  );
};
