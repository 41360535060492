import Button from '@guaranteed-rate/react-components/dist/Button';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import { useContext, useState } from 'react';
import { IPropertyForm } from './HelocPropertyPage';
import GoogleMapAddress from '../../../components/GoogleMapAddress/GoogleMapAddress';
import Alert from '../../../components/Alert/Alert';
import { IProperty, IPropertyResponse } from '../../../config/util/interfaces';
import { MASKS } from '../../../config/util/masks';
import Form from '../../../components/Form/Form';
import { HelocContext } from '../HelocPage';

interface IPropertyPageProps {
  helocForm: IPropertyForm;
  handleSubmit: any;
  loading: boolean;
  isSaleProperty: boolean;
  setSaleProperty: (isSaleProperty: boolean) => void;
  loanAmount: string;
  setLoanAmount: (amount: string) => void;
  propertyData: IPropertyResponse;
  setHelocForm: (data: IPropertyForm) => void;
  trySubmit: boolean;
}

// build the current address based on the input object. Used when we go backwards
export const buildLocation = (propertyData: IPropertyResponse) => {
  if (propertyData?.address?.city != null) {
    return buildLocationFromProperty(propertyData.address);
  }
  return '';
};

// This function is used to check if the address is complete one or not
function getIsFullAddress(addressGroupNames: Array<any>, form: any) {
  if (!addressGroupNames || !form.address) {
    return true;
  }
  return Object.values(addressGroupNames).every(
    (key) => form[key] !== '' || key === 'apartment'
  );
}

export const buildLocationFromProperty = (property: IProperty) =>
  `${property.street[0]}${property.street.length > 1 ? ` ${property.street[1]}` : ''
  } ${property.city} ${property.region} ${property.postalCode}`;

const PropertyPage = ({
  handleSubmit,
  setSaleProperty,
  propertyData,
  setHelocForm,
  setLoanAmount,
  loanAmount,
  isSaleProperty,
  loading,
  helocForm,
  trySubmit
}: IPropertyPageProps) => {
  const { progress, content } = useContext(HelocContext);

  const [formattedAddress, setFormattedAddress] = useState(
    buildLocation(propertyData)
  ); // this is the property address when formatted

  const isFullAddress = trySubmit
    && getIsFullAddress(content?.address_group_names, helocForm);

  // This is passed to the GoogleMaps component so that we can save the new address to the property form
  const onAddressChange = (address: any, type: string) => {
    setHelocForm(
      type === 'apartment'
        ? {
          ...helocForm,
          unitNumber: address.unitNumber,
        }
        : {
          ...helocForm,
          address: address.address,
          unitNumber: address.unitNumber,
          city: address.city,
          state: address.state,
          zip: address.zip,
        }
    );
    setFormattedAddress(address.formattedAddress);
  };

  // validates the loan amount is within the appropriate bounds
  const loanAmountError = () => {
    const amountNumber = !loanAmount ? 0 : parseInt(loanAmount);
    if (amountNumber <= 0) {
      return true;
    }
    if (
      amountNumber < (helocForm.state === 'AK' ? 25001 : 20000)
      || amountNumber > 400000
    ) {
      return true;
    }
    return false;
  };

  return (
    <Form title={content.header} progress={progress}>
      <div>
        <div className="min-h-[300px]">
          {isSaleProperty && (
            <div className="mb-4">
              <Alert
                text={content.property_listed_for_sale_error}
                type="error"
                icon="warning-triangle"
              />
            </div>
          )}
          <GoogleMapAddress
            onChange={onAddressChange}
            value={formattedAddress}
            addressObj={helocForm}
            label={content.address_field_label}
            type="address"
            hasError={trySubmit && (!helocForm.address || !isFullAddress)}
            helperText={
              trySubmit && !isFullAddress
                ? content?.full_address_error_label
                : 'required'
            }
            required={true}
          />
          <TextInput
            name="loanAmount"
            value={loanAmount}
            label={content.loan_amount_label}
            onChange={(input) => setLoanAmount(input.target.value)}
            mask={MASKS.CURRENCY}
            hasError={trySubmit && loanAmountError()}
            helperText={
              trySubmit && loanAmountError()
                ? `Please enter a value between $${helocForm.state === 'AK' ? '25,001' : '20,000'
                } and $400,000`
                : undefined
            }
            required={true}
          />
          <div className="flex">
            <input
              type="checkbox"
              id="isSaleProperty"
              className="checkbox mr-2 mb-6"
              checked={isSaleProperty}
              onChange={() => setSaleProperty(!isSaleProperty)}
            />
            <label>{content?.property_listed_for_sale_label}</label>
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <Button
            buttonStyle="primary"
            className="!w-full md:!w-48 nextButton"
            onClick={handleSubmit}
            loading={loading}
            buttonAttrs={{ disabled: loading || isSaleProperty }}
          >
            Next
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default PropertyPage;
