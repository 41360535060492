import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import DropDownSearch from '@guaranteed-rate/react-components/dist/DropDownSearch';
import { useContext, useEffect, useState } from 'react';
import Form from '../../../components/Form/Form';
import { IPropertyResponse } from '../../../config/util/interfaces';
import { MASKS } from '../../../config/util/masks';
import { getLoCookie } from '../../../config/util/common';
import { HelocOfficerPage } from '../HelocOfficerPage/HelocOfficerPage';
import { helocLiteStates } from '../../../config/content/dropdown';
import { HelocContext } from '../HelocPage';

interface IPropertyProps {
  propertyData: IPropertyResponse;
  loanOfficers: any;
  setLo: (loId: string) => void;
}

// This component is used to render the property, annual, occupancy and purpose pages
export const HelocLoanInfo = (props: IPropertyProps) => {
  const { progress, handleBackSubmit, pageName, handlePageSubmit, content } = useContext(HelocContext);
  const [region, setRegion] = useState(props.propertyData?.address?.region || '');
  const [estimatedValue, setEstimatedValue] = useState(props.propertyData?.estimatedValue
    ? `${props.propertyData.requestedLoanAmount}` : '');
  const [loanAmount, setLoanAmount] = useState(props.propertyData?.requestedLoanAmount ? `${props.propertyData.requestedLoanAmount}` : ''); // requested loan amount

  const [loading, setLoading] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);
  const [currentPage, setCurrentPage] = useState(pageName);

  // when the user clicks next they submit their data to go to the next page
  const handleSubmit = async () => {
    setTrySubmit(true);

    // validates the input based on the current page (annual or property)
    if (!loanAmountError() && region !== '' && estimatedValue !== '') {
      if (currentPage === 'loanInfo') {
        setCurrentPage('officer');
        window.history.pushState('officer', 'Title', '/heloc/lite/officer');
        return;
      }
      setLoading(true);
      const cookiedId = getLoCookie();
      const pageData = `{ 
        page: "loanInfo"
        lite: true
        propertyData: { 
          state: "${region}"
          estimatedValue: "${estimatedValue}"
          loanAmount: "${loanAmount}"
          loanOfficerId: "${cookiedId || ''}"
        }
      }`;
      await handlePageSubmit(pageData);
      setLoading(false);
      setTrySubmit(false);
    }
  };

  // validates the loan amount is within the appropriate bounds
  const loanAmountError = () => {
    if (loanAmount.length <= 0) {
      return true;
    }
    const amountNumber = parseInt(loanAmount);
    if (amountNumber < 50000 || amountNumber > 500000) {
      return true;
    }
    return false;
  };
  const loanToValueError = () => {
    if (loanAmount.length <= 0 || estimatedValue.length <= 0) {
      return true;
    }
    const amountNumber = parseInt(loanAmount);
    const valueNumber = parseInt(estimatedValue);
    if (amountNumber > valueNumber) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (props.loanOfficers && props.loanOfficers.length > 0 && currentPage === 'officer') {
      setLoading(false);
    }
  }, [props.loanOfficers]);

  // pre-select dropdown search
  useEffect(() => {
    const input = document.getElementsByClassName('dropDownSearchBox')[0]?.querySelector('input');
    if (input) {
      const name = helocLiteStates.find((option) => option.value === region)?.name;
      if (name) {
        input.value = name;
        const label = document.getElementById('dropDownSearch-label');
        if (label) {
          label.className = 'filled';
        }
      }
    }
  }, [region, currentPage]);

  if (currentPage === 'officer') {
    return (
      <HelocOfficerPage
        content={content.officer}
        setLo={props.setLo}
        helocForm={{ state: region } as any}
        handleSubmit={handleSubmit}
        handleBack={() => handleBackSubmit(pageName)}
        setLoading={setLoading}
        loanOfficers={props.loanOfficers}
        loading={loading}
      />
    );
  }

  return (
    <Form title={content.header} progress={progress}>
      {currentPage === 'loanInfo'
      && (
        <div>
          <h4 className="mb-4">{content.prompt}</h4>
          <div className="min-h-[300px]">
            <DropDownSearch
              items={helocLiteStates}
              optionLabel="name"
              label={content.state_label}
              onChange={(option) => option && setRegion(option.value)}
              hasError={trySubmit && region === ''}
              helperText={trySubmit && region === '' ? 'required' : ''}
              required={true}
            />
            <TextInput
              name="estimatedValue"
              value={estimatedValue}
              label={content.value_label}
              onChange={(input) => setEstimatedValue(input.target.value)}
              mask={MASKS.CURRENCY}
              hasError={trySubmit && (estimatedValue === '')}
              helperText={trySubmit && estimatedValue === '' ? 'required' : ''}
              required={true}
            />
            <TextInput
              name="loanAmount"
              value={loanAmount}
              label={content.loan_amount_label}
              onChange={(input) => setLoanAmount(input.target.value)}
              mask={MASKS.CURRENCY}
              hasError={trySubmit && (loanAmountError() || loanToValueError())}
              helperText={trySubmit
                ? (loanAmountError()
                  ? 'Please enter a value between $50,000 and $500,000.'
                  : loanToValueError()
                    ? 'Requested loan amount must be below the estimated value.' : '')
                : ''}
              required={true}
            />
          </div>
          <div className="flex justify-center mt-4">
            <Button
              buttonStyle="primary"
              className="!w-full md:!w-48 nextButton"
              onClick={() => handleSubmit()}
              loading={loading}
              buttonAttrs={{ disabled: loading }}
            >Next
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};
