/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import { useCallback, useContext, useState } from 'react';
import Form from '../../../components/Form/Form';
import { MASKS } from '../../../config/util/masks';
import { IBasicInfoData, IPropertyResponse } from '../../../config/util/interfaces';
import { formatRichText, getDOBErrorText } from '../../../config/util/common';
import { HelocAccountPage } from '../HelocAccountPage/HelocAccountPage';
import CalendarInput from '../../../components/CalendarInput/CalendarInput';
import { HelocContext } from '../HelocPage';

interface ISSNProps {
  basicInfoData: IBasicInfoData;
  setGlobalLoading: any;
  propertyInfoData: IPropertyResponse;
}

export const HelocSSNPage = (props: ISSNProps) => {
  const { progress, handleBackSubmit, pageName, content, isLite } = useContext(HelocContext);
  const [helocForm, setHelocForm] = useState({ birthday: '', ssn: '', lastFourSSN: '' });
  const [trySubmit, setTrySubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSSN, setShowSSN] = useState(false);
  const [showAccountScreen, setShowAccountScreen] = useState(false);
  const [showCreditScreen, setShowCreditScreen] = useState(false);
  const [creditChecked, setCreditChecked] = useState(false);

  // used to save the identifier and birthdate to the orchestrator and move to next screen
  const handleSubmit = async () => {
    setTrySubmit(true);
    if (canSubmit()) {
      window.scrollTo(0, 0);
      if (isLite) {
        setShowCreditScreen(true);
      } else {
        setShowAccountScreen(true);
      }
    }
  };

  const handleCreditSubmit = () => {
    setShowAccountScreen(true);
  };

  const handleCreditBack = () => {
    setShowCreditScreen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setHelocForm({ ...helocForm, [name !== '' ? name : event.target.name]: event.target.value });
  };

  // verifies the users age
  const isValidDate = useCallback(() => !getDOBErrorText(helocForm.birthday), [helocForm.birthday]);
  const validSSNDataCaptured = () => (isLite ? helocForm.ssn.length === 9 : helocForm.lastFourSSN.length === 4);

  const canSubmit = () => validSSNDataCaptured() && helocForm.birthday.length > 0 && isValidDate();

  return (
    <div>
      { showAccountScreen
        ? (
          <HelocAccountPage
            content={content.account}
            basicInfoData={props.basicInfoData}
            setGlobalLoading={props.setGlobalLoading}
            propertyInfoData={props.propertyInfoData}
            form={helocForm}
            progress={progress}
            isLite={isLite}
            setShowAccount={setShowAccountScreen}
          />
        )
        : showCreditScreen
          ? (
            <Form title={content.credit.header} progress={progress}>
              <div className="min-h-[300px]">
                <h4 className="mb-8">{content.credit.description}</h4>
                <div>
                  <div className="flex flex-row mb-4">
                    <div>
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={creditChecked}
                        onChange={() => setCreditChecked(!creditChecked)}
                      />
                    </div>
                    <h4>
                      <div className="text-sm text-left">
                        {
                          formatRichText('credit', content.credit.checkbox_content, content.credit)
                        }
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-12">
                <Button
                  buttonStyle="primary"
                  className="!w-full md:!w-48 nextButton"
                  onClick={handleCreditSubmit}
                  buttonAttrs={{ disabled: !creditChecked || loading }}
                  loading={loading}
                >Confirm
                </Button>
              </div>
              <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
                <Button
                  buttonStyle="quaternary"
                  iconPos="left"
                  iconName="chevron-left-large"
                  onClick={handleCreditBack}
                  buttonAttrs={{ disabled: loading }}
                >Back
                </Button>
              </div>
            </Form>
          )
          : (
            <Form title={content.header} progress={progress}>
              <div className="min-h-[300px]">
                {isLite ? (
                  <h4 className="mb-4">{content.description}</h4>
                ) : (
                  <h4 className="mb-4">{content.heloc_description}</h4>
                )}
                <div className="md:grid md:grid-cols-2 md:space-x-4">
                  <div onClick={(event: any) => event.target.className.indexOf('eye') !== -1 && setShowSSN(!showSSN)}>
                    {isLite ? (
                      <TextInput
                        name="ssn"
                        value={helocForm.ssn}
                        label={content.ssn_field_label}
                        mask={showSSN ? MASKS.SSN : MASKS.SSN_PW}
                        type="password"
                        onChange={(input) => handleChange(input, 'ssn')}
                        hasError={trySubmit && helocForm.ssn.length < 9}
                        helperText={trySubmit && helocForm.ssn.length < 9 ? 'Please enter a valid ssn' : ''}
                      />
                    ) : (
                      <TextInput
                        name="lastFourSSN"
                        value={helocForm.lastFourSSN}
                        label={content.last_four_digit_ssn_label}
                        type="password"
                        onChange={(input) => handleChange(input, 'lastFourSSN')}
                        hasError={trySubmit && helocForm.lastFourSSN.length < 4}
                        helperText={trySubmit && helocForm.lastFourSSN.length < 4 ? 'Please enter a valid 4 digit ssn' : ''}
                        maxLength={4}
                      />
                    )}
                  </div>
                  <div>
                    <CalendarInput
                      label={content.birthday_label}
                      className="w-full birthdayInput"
                      name="birthday"
                      form={helocForm}
                      setForm={setHelocForm}
                      hasError={trySubmit && !isValidDate()}
                      helperText={getDOBErrorText(helocForm.birthday)}
                      id="date"
                      required={true}
                    />
                  </div>
                </div>
                <p className="text-xs">{content.disclaimer}</p>
              </div>
              <div className="flex justify-center mt-12">
                <Button
                  buttonStyle="primary"
                  className="!w-full md:!w-64 confirmButton"
                  onClick={handleSubmit}
                  buttonAttrs={{ disabled: loading }}
                  loading={loading}
                >I agree and continue
                </Button>
              </div>
              <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
                <Button
                  buttonStyle="quaternary"
                  iconPos="left"
                  iconName="chevron-left-large"
                  onClick={() => handleBackSubmit(pageName)}
                  buttonAttrs={{ disabled: loading }}
                >Back
                </Button>
              </div>
              <div className="text-center pt-12 ">
                <p>{content.income_description}</p>
                <p>{content.other_income_description}</p>
              </div>
            </Form>
          )}
    </div>
  );
};
