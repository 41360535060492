import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import { useCallback, useContext, useEffect, useState } from 'react';
import Form from '../../../components/Form/Form';
import { IProperty, IPropertyResponse } from '../../../config/util/interfaces';
import { MASKS } from '../../../config/util/masks';
import { getLoCookie } from '../../../config/util/common';
import { HelocOfficerPage } from '../HelocOfficerPage/HelocOfficerPage';
import { HelocOwnershipPage } from '../HelocAdditionalQuestions/HelocOwnershipPage';
import { PROPERTY_PAGES, HELOC_PROPERTY_PAGES, HELOC_LITE_PROPERTY_PAGES } from '../../../config/content/constants';
import PropertyPage from './PropertyPage';
import OccupancyAndPurposePage from './OccupancyAndPurposePage';
import { HelocContext } from '../HelocPage';

interface IPropertyProps {
  propertyData: IPropertyResponse;
  loanOfficers?: Array<any>;
  setLo: (loId: string) => void;
}

export interface IPropertyForm {
  address: string;
  unitNumber: string;
  city: string;
  state: string;
  zip: string;
  occupancy: string;
  property_type?: string;
  purpose: string;
  isSaleProperty: string;
  ownershipType: string;
  trustName: string;
  otherReason: string;
}
interface IAnnualForm {
  annualTaxes: string;
  annualInsurance: string;
  annualHoa: string;
}

// build the current address based on the input object. Used when we go backwards
export const buildLocation = (props: IPropertyProps) => {
  if (props.propertyData?.address?.city != null) {
    return buildLocationFromProperty(props.propertyData.address);
  }
  return '';
};

export const buildLocationFromProperty = (property: IProperty) => `${property.street[0]}${property.street.length > 1
  ? ` ${property.street[1]}` : ''} ${property.city} ${property.region} ${property.postalCode}`;

// load the existing form with the previously entered data, if available
const loadExistingData = (props: IPropertyProps) => {
  if (props.propertyData != null) {
    return {
      address: props.propertyData?.address?.street ? props.propertyData?.address?.street[0] : '',
      unitNumber: props.propertyData?.address?.street?.length > 1 ? props.propertyData.address.street[1] : '',
      city: props.propertyData?.address?.city,
      state: props.propertyData?.address?.region,
      zip: props.propertyData?.address?.postalCode,
      property_type: props.propertyData?.structureType || '',
      occupancy: props.propertyData?.propertyType || '',
      purpose: props.propertyData?.purpose || '',
      ownershipType: props.propertyData?.ownershipType || '',
      trustName: props.propertyData?.trustName || '',
      otherReason: props.propertyData?.otherReason || ''
    } as IPropertyForm;
  }
  return {
    address: '',
    unitNumber: '',
    city: '',
    state: '',
    zip: '',
    property_type: '',
    occupancy: '',
    purpose: '',
    ownershipType: '',
    trustName: '',
    otherReason: ''
  } as IPropertyForm;
};

const getPropertyDataForGq = (helocForm: IPropertyForm, loanAmount: string, isLite: boolean) => {
  const cookiedId = getLoCookie();
  return `{ 
    address: "${helocForm.address}"
    apt: "${helocForm.unitNumber}"
    city: "${helocForm.city}"
    state: "${helocForm.state}"
    zip: "${helocForm.zip}"
    loanAmount: "${loanAmount}"
    loanOfficerId: "${cookiedId || ''}"
    ${!isLite ? `ownershipType: "${helocForm.ownershipType || ''}"` : ''} 
    trustName: "${helocForm.trustName || ''}"
  }`;
};

const getAnnualIncomDataForGq = (annualForm: IAnnualForm) => `{
  annualTaxes: "${annualForm.annualTaxes}"
  annualInsurance: "${annualForm.annualInsurance}"
  annualHoa: "${annualForm.annualHoa}"
}`;

// load previously entered taxes and insurance if available
const loadAnnualData = (props: IPropertyProps) => {
  if (props.propertyData != null) {
    return {
      annualTaxes: props?.propertyData?.annualTaxes?.toString() || '',
      annualInsurance: props?.propertyData?.annualInsurance?.toString() || '',
      annualHoa: props?.propertyData?.annualHoa?.toString() || ''
    } as IAnnualForm;
  }
  return {
    annualTaxes: '',
    annualInsurance: '',
    annualHoa: ''
  } as IAnnualForm;
};

const checkBack = (isLite: boolean, back: string, pageName: string, setBack: any) => {
  let page = pageName;
  if (back) {
    if (isLite) {
      page = HELOC_LITE_PROPERTY_PAGES[HELOC_LITE_PROPERTY_PAGES.length - 1];
    } else {
      page = HELOC_PROPERTY_PAGES[HELOC_PROPERTY_PAGES.length - 1];
    }
    setBack('');
  }
  return page;
};

// This component is used to render the property, annual, occupancy and purpose pages
export const HelocPropertyPage = (props: IPropertyProps) => {
  const { progress, back, setBack, pageName, handlePageSubmit, content, isLite } = useContext(HelocContext);
  const [helocForm, setHelocForm] = useState(loadExistingData(props)); // this is the property data
  const [loanAmount, setLoanAmount] = useState(`${props.propertyData?.requestedLoanAmount ? props.propertyData?.requestedLoanAmount : ''}`); // requested loan amount
  const [annualForm, setAnnualForm] = useState(loadAnnualData(props)); // this is the insurance and taxes form
  const [loading, setLoading] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);
  const loId = getLoCookie();
  const [isSaleProperty, setSaleProperty] = useState(false);
  const [currentPage, setCurrentPage] = useState<string>(checkBack(isLite, back, pageName, setBack));
  useEffect(() => {
    if (currentPage === PROPERTY_PAGES.PURPOSE) {
      setHelocForm({ ...helocForm, purpose: '', otherReason: '' });
    } else if (currentPage === PROPERTY_PAGES.OCCUPANCY) {
      setHelocForm({ ...helocForm, occupancy: '' });
    }
    if (window.location.href.indexOf(currentPage) === -1) {
      window.history.pushState(currentPage, 'Title', `/heloc/${isLite ? 'lite/' : ''}${currentPage}${window.location.search}`);
    }
  }, [currentPage]);

  // set the non sensitive data to the window so that adobe can pull it for testing
  const setNonSenstiveDataToWindow = () => {
    (window as any).digitalData = {
      pageInfo: {
        propertyData: {
          city: helocForm.city,
          state: helocForm.state,
          zip: helocForm.zip,
          loanAmount,
          loanOfficerId: loId
        },
        annualPaymentData: {
          annualTaxes: annualForm.annualTaxes,
          annualInsurance: annualForm.annualInsurance
        },
        occupancy: helocForm.occupancy,
        purpose: helocForm.purpose
      }
    };
  };

  // used to update the property data
  const updateHelocForm = (value: string, name: string) => {
    setHelocForm({ ...helocForm, [name]: value });
  };

  // used to update the taxes and insurance data
  const handleAnnualChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setAnnualForm({ ...annualForm, [name !== '' ? name : event.target.name]: event.target.value });
  };

  // validates the loan amount is within the appropriate bounds
  const loanAmountError = () => {
    const amountNumber = !loanAmount ? 0 : parseInt(loanAmount);
    if (amountNumber <= 0) {
      return true;
    }
    if (amountNumber < (helocForm.state === 'AK' ? 25001 : 20000) || amountNumber > 400000) {
      return true;
    }
    return false;
  };

  // when the user clicks next they submit their data to go to the next page
  const handleSubmit = async (form: any, nextPage: string) => {
    setTrySubmit(true);
    const keys = form ? Object.keys(form) : [];
    // if: validates the input based on the current page (annual or property)
    if ((loanAmount.length > 0
        && keys.every(
          (key) =>
            form[key].length > 0
            || key === 'apartment'
            || key === 'occupancy'
            || key === 'purpose'
            || key === 'property_type'
            || key === 'annualHoa'
            || key === 'ownershipType'
            || key === 'trustName'
            || key === 'unitNumber'
            || key === 'otherReason'
        )
        && ((nextPage === PROPERTY_PAGES.OFFICER && !loanAmountError())
          || nextPage !== PROPERTY_PAGES.OFFICER))
    ) {
      if (!isLite && nextPage === PROPERTY_PAGES.OFFICER && loId) {
        const nextPage = HELOC_PROPERTY_PAGES[HELOC_PROPERTY_PAGES.indexOf(PROPERTY_PAGES.OFFICER) + 1];
        setCurrentPage(nextPage);
      } else {
        setCurrentPage(nextPage);
      }
    }
  };

  // This is called when the user selects a loan purpose or occupancy type
  const handleSelect = async (value: string) => {
    // Here we pass in the page name as the key for the value selected from the radio button
    // If the page is occupancy then we just want to save the data locally and go to the loan purpose page
    updateHelocForm(value, currentPage);
    if (isLite) {
      const index = HELOC_LITE_PROPERTY_PAGES.indexOf(currentPage as any);
      if (index !== -1 && index < HELOC_LITE_PROPERTY_PAGES.length - 1) {
        setCurrentPage(HELOC_LITE_PROPERTY_PAGES[index + 1]);
      } else {
        handleFinalSubmit(currentPage, value);
      }
    } else {
      const index = HELOC_PROPERTY_PAGES.indexOf(currentPage as any);
      if (index !== -1 && index < HELOC_PROPERTY_PAGES.length - 1) {
        setCurrentPage(HELOC_PROPERTY_PAGES[index + 1]);
      }
    }
  };

  const handleFinalSubmit = async (key: string, value: string) => {
    setLoading(true);
    const pageData = `{ 
      page: "${pageName}"
      ${isLite ? 'lite: true' : ''}
      propertyData: ${getPropertyDataForGq(helocForm, loanAmount, isLite || false)}
      annualPaymentData: ${getAnnualIncomDataForGq(annualForm)}
      occupancy: "${helocForm.occupancy}"
      ${isLite ? `propertyType: "${helocForm.property_type}"` : ''}
      purpose: "${key === 'purpose' ? value : helocForm.purpose}"
      otherReason: "${helocForm.otherReason}"
    }`;

    // set the non sensitive data to the window so that adobe can pull it for testing
    setNonSenstiveDataToWindow();
    await handlePageSubmit(pageData);
  };

  // This is used to go backwards
  const handleBack = useCallback(async () => {
    if (isLite) {
      const index = HELOC_LITE_PROPERTY_PAGES.indexOf(currentPage as any);
      if (index > 0) {
        setCurrentPage(HELOC_LITE_PROPERTY_PAGES[index - 1]);
      }
    } else {
      const index = HELOC_PROPERTY_PAGES.indexOf(currentPage as any);
      if (index > 0) {
        if (HELOC_PROPERTY_PAGES[index - 1] === PROPERTY_PAGES.OFFICER && loId) {
          setCurrentPage(HELOC_PROPERTY_PAGES[index - 2]);
        } else {
          setCurrentPage(HELOC_PROPERTY_PAGES[index - 1]);
        }
      }
    }
  }, [currentPage]);

  const hasError = (name: string, form: any) => trySubmit && form[name].length <= 0;

  if (currentPage === PROPERTY_PAGES.PARTNERSHIP) {
    return (
      <HelocOwnershipPage
        handleSubmit={() => (handleFinalSubmit(PROPERTY_PAGES.PARTNERSHIP, ''))}
        handleBackSubmit={handleBack}
        additionalInfo={helocForm}
        loading={loading}
        setLoading={setLoading}
        setAdditionalInfo={setHelocForm}
      />
    );
  }

  if (currentPage === PROPERTY_PAGES.PROPERTY) {
    return (
      <PropertyPage
        helocForm={helocForm}
        handleSubmit={() => handleSubmit(helocForm, PROPERTY_PAGES.OFFICER)}
        loading={loading}
        setHelocForm={setHelocForm}
        loanAmount={loanAmount}
        setLoanAmount={setLoanAmount}
        isSaleProperty={isSaleProperty}
        setSaleProperty={setSaleProperty}
        trySubmit={trySubmit}
        propertyData={props.propertyData}
      />
    );
  }

  if (currentPage === PROPERTY_PAGES.OFFICER) {
    return (
      <HelocOfficerPage
        content={content.officer}
        setLo={props.setLo}
        helocForm={helocForm}
        handleSubmit={() => handleSubmit(helocForm, PROPERTY_PAGES.OCCUPANCY)}
        handleBack={handleBack}
        setLoading={setLoading}
        loanOfficers={props.loanOfficers}
        loading={loading}
      />
    );
  }

  if (PROPERTY_PAGES.ANNUAL === currentPage && isLite) {
    return (
      <Form title={content.annual.header} progress={progress}>
        <div>
          <div className="min-h-[300px]">
            <h4 className="mb-4">{content.annual.description}</h4>
            <div className={isLite ? 'md:flex md:space-x-4' : 'block'}>
              <TextInput
                name="annualTaxes"
                className="grow"
                value={annualForm.annualTaxes}
                label={content.annual.annual_taxes_label}
                onChange={(input) => handleAnnualChange(input, 'annualTaxes')}
                mask={MASKS.CURRENCY}
                hasError={hasError('annualTaxes', annualForm)}
                helperText={hasError('annualTaxes', annualForm) ? 'required' : undefined}
                required={true}
              />
              <TextInput
                name="annualInsurance"
                value={annualForm.annualInsurance}
                className="grow"
                label={content.annual.annual_insurance_label}
                onChange={(input) => handleAnnualChange(input, 'annualInsurance')}
                mask={MASKS.CURRENCY}
                hasError={hasError('annualInsurance', annualForm)}
                helperText={hasError('annualInsurance', annualForm) ? 'required' : undefined}
                required={true}
              />
            </div>
            <TextInput
              name="annualHoa"
              value={annualForm.annualHoa}
              label={content.annual.annual_hoa_label}
              onChange={(input) => handleAnnualChange(input, 'annualHoa')}
              mask={MASKS.CURRENCY}
            />
          </div>
          <div className="flex justify-center mt-4">
            <Button
              buttonStyle="primary"
              className="!w-full md:!w-48 annualNext"
              onClick={() => handleSubmit(annualForm, PROPERTY_PAGES.OCCUPANCY)}
              loading={loading}
              buttonAttrs={{ disabled: loading }}
            >Next
            </Button>
          </div>
          <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
            <Button
              buttonStyle="quaternary"
              iconPos="left"
              iconName="chevron-left-large"
              onClick={handleBack}
              buttonAttrs={{ disabled: loading }}
            >Back
            </Button>
          </div>
        </div>
      </Form>
    );
  }

  // if (PROPERTY_PAGES.PURPOSE === currentPage || currentPage === PROPERTY_PAGES.OCCUPANCY || currentPage === PROPERTY_PAGES.PROPERTY_TYPE) {
  return (
    <OccupancyAndPurposePage
      loading={loading}
      content={content}
      progress={progress}
      handleSelect={handleSelect}
      updateHelocForm={updateHelocForm}
      pageName={currentPage}
      helocForm={helocForm}
      isLite={isLite}
      handleBack={handleBack}
    />
  );
  // }
};
