/* eslint-disable no-debugger */
import { useContext, useEffect, useState } from 'react';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import { v4 as uuidv4 } from 'uuid';
import Form from '../../../components/Form/Form';
import { IBasicInfoData } from '../../../config/util/interfaces';
import { HelocContext } from '../HelocPage';

interface IDiclosureProps {
  basicInfoData: IBasicInfoData;
}

export const HelocInitialDisclosures = (props: IDiclosureProps) => {
  const { progress, pageName, handlePageSubmit, applicationId, content } = useContext(HelocContext);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [id, setId] = useState('');
  const [borrowerId, setBorrowerId] = useState('');
  const [requestId, setRequestId] = useState('');
  const [urlFetched, setUrlFetched] = useState(false);

  const fetchUrl = async () => {
    setUrlFetched(true);
    const mutation = {
      query:
        `{
          queryInitialDisclosures(applicationId: "${applicationId}", basicInfoId: "${props.basicInfoData.id}") {
            id
            borrowerId
            documentUrl
            documentRequestId
          }
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.queryInitialDisclosures && data?.data?.queryInitialDisclosures.documentUrl !== '') {
        setId(data.data.queryInitialDisclosures.id);
        setUrl(data.data.queryInitialDisclosures.documentUrl);
        setBorrowerId(data.data.queryInitialDisclosures.borrowerId);
        setRequestId(data.data.queryInitialDisclosures.documentRequestId);
      } else {
        setTimeout(() => {
          setUrlFetched(false);
          fetchUrl();
        }, 5000);
      }
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        setUrlFetched(false);
        fetchUrl();
      }, 5000);
      return e;
    }
  };

  if (!urlFetched) {
    fetchUrl();
  }

  const handleSubmit = async (borrowerId: string, id: string, requestId: string) => {
    setLoading(true);
    const pageData = `{ 
        page: "${pageName}"
        documentInput: {
          borrowerId: "${borrowerId}"
          documentId: "${id}"
          documentRequestId: "${requestId}"
        }
      }`;
    await handlePageSubmit(pageData);
    setLoading(false);
  };

  const receiveMessage = (event: any, borrowerId: string, id: string, requestId: string) => {
    let origin = event.origin || event.originalEvent.origin;
    if (origin.indexOf('solex') === -1) {
      return;
    }
    console.log(event.data);
    if (event.data.type === 'signOutClick' || event.data.type === 'signOut') {
      handleSubmit(borrowerId, id, requestId);
    }
  };
  useEffect(() => {
    if (borrowerId !== '') {
      window.addEventListener('message', (event: any) => receiveMessage(event, borrowerId, id, requestId), false);
    }
  }, [borrowerId]);

  return (
    <Form title={url !== '' ? content.header : content.header2} progress={progress}>
      <div>
        { url !== '' ? <iframe title="initialDisclosures" src={url} className="w-full min-h-[640px] shadow-md rounded-lg" />
          : (
            <div className="flex justify-center w-full">
              <div className="min-h-[420px] max-w-2xl w-full">
                <div className="flex justify-start w-full">
                  <p className="mb-12">{content.description}</p>
                </div>
                <div className="flex items-center justify-center">
                  <Loader color="#D13239" className="loader-medium" />
                </div>
              </div>
            </div>
          ) }
      </div>
    </Form>
  );
};
