/* eslint-disable max-len */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Cookies from 'js-cookie';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import Form from '../../../components/Form/Form';
import { fetchFspPageContent, localeOptions } from '../../../config/util/common';

interface IApplicationProps {
  loadApplication: any;
}

export const HelocApplicationsPage = (props: IApplicationProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(null as any);
  const [applications, setApplications] = useState([]);
  const [fetchContent, setFetchContent] = useState(false);

  const setFSPContent = async () => {
    setFetchContent(true);
    const data = await fetchFspPageContent('"heloc_applications_page"');
    if (data?.content === 'error') {
      navigate('/heloc/error/');
    } else {
      setContent(data?.content);
    }
    setFetchContent(false);
  };

  const fetchApplications = async () => {
    setLoading(true);
    let token = Cookies.get('authToken') || '';
    if (token === '') {
      window.location.href = `${window.location.origin}/gateway/authorize-heloc-user?tenant-id=${(window as any).tenantId.toLowerCase()}`;
    } else {
      const mutation = {
        query:
        `query {
          fetchHelocApplications {
            loanAmount
            address {
              street
              city
              region
              postalCode
            }
            startDate
            id
          }
        }`
      };
      const uuid = uuidv4();
      const url = '/gateway/graphql';
      try {
        const resp = await fetch(url, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
            'X-Request-ID': uuid,
            Authorization: token
          },
          body: JSON.stringify(mutation)
        });
        const data = await resp.json();
        if (data?.data?.fetchHelocApplications) {
          setApplications(data.data.fetchHelocApplications.map((application: any) => ({
            address: '34 Ximeno Ave',
            loanAmount: application.loanAmount,
            id: application.id,
            startDate: new Date(application.startDate).toLocaleString('en-us', {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit' })
          })));
          setLoading(false);
        } else {
          setLoading(false);
          Cookies.remove('authToken');
          window.location.href = `${window.location.origin}/gateway/authorize-heloc-user?tenant-id=${(window as any).tenantId.toLowerCase()}`;
        }
      } catch (e) {
        setLoading(false);
        navigate('/heloc/error/');
      }
    }
  };

  if (!content && !fetchContent) {
    setFSPContent();
  }
  if (applications.length === 0 && !loading) {
    fetchApplications();
  }

  const selectApplication = async (id: string) => {
    let token = Cookies.get('authToken') || '';
    if (token === '') {
      window.location.href = `${window.location.origin}/gateway/authorize-heloc-user?tenant-id=${(window as any).tenantId.toLowerCase()}`;
    } else {
      setLoading(true);
      const mutation = {
        query:
        `query {
          suspendOtherHelocApplications(applicationId: "${id}")
        }`
      };
      const uuid = uuidv4();
      const url = '/gateway/graphql';
      try {
        const resp = await fetch(url, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
            'X-Request-ID': uuid,
            Authorization: token
          },
          body: JSON.stringify(mutation)
        });
        const data = await resp.json();
        if (data?.data?.suspendOtherHelocApplications) {
          props.loadApplication(id);
        } else {
          setLoading(false);
          Cookies.remove('authToken');
          window.location.href = `${window.location.origin}/gateway/authorize-heloc-user?tenant-id=${(window as any).tenantId.toLowerCase()}`;
        }
      } catch (e) {
        setLoading(false);
        navigate('/heloc/error/');
      }
    }
  };

  return (
    <div>
      {
        content?.header && (
          <Form title={content.header}>
            <div className="min-h-[420px]">
              <h3 className="mb-4 font-bold">{content.prompt}</h3>
              {
                loading ? (
                  <div className="flex items-center justify-center mt-16">
                    <Loader color="#D13239" className="loader-medium" />
                  </div>
                )
                  : applications.map((application: any) => (
                    <div
                      key={`${application.id}`}
                      className="p-4 mb-4 border rounded-lg cursor-pointer applicationButton"
                      onClick={() => selectApplication(application.id)}
                    >
                      <h3 className="font-bold mb-4">{application.address}</h3>
                      <div className="grid grid-cols-2">
                        <div>
                          <h4>Created {application.startDate}</h4>
                        </div>
                        <div>
                          <h4>{parseFloat(application.loanAmount).toLocaleString('en-us', localeOptions)} loan</h4>
                        </div>
                      </div>
                      <div className="float-right -mt-12">
                        <FontIcon iconName="chevron-right-large" fontSize="24px" />
                      </div>
                    </div>
                  ))
              }
            </div>
          </Form>
        )
      }
    </div>
  );
};
