/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useContext, useEffect, useState } from 'react';
import Button from '@guaranteed-rate/react-components/dist/Button';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import Form from '../../../components/Form/Form';
import { IAccountInfo, IBankInfo } from '../../../config/util/interfaces';
import { MASKS } from '../../../config/util/masks';
import { getAccountNumberError, hashNotLastFour, toFirstLetterCapital } from '../../../config/util/common';
import Card from '../../../components/Card/Card';
import { HelocBankVerification } from '../HelocVerifyPage/HelocBankVerification';
import Alert from '../../../components/Alert/Alert';
import { HelocContext } from '../HelocPage';
// eslint-disable-next-line @typescript-eslint/no-var-requires

interface IBankProps {
  depositInfo: IBankInfo;
  debitInfo: IBankInfo;
  accounts: [IAccountInfo];
}

interface IBankForm {
  name: string;
  routingNumber: string;
  accountNumber: string;
}

const newBankForm = () => ({
  name: '',
  routingNumber: '',
  accountNumber: '',
}) as IBankForm;

const imageStyles = {
  height: '46px',
  width: '41px',
};

const loadLinkedAccounts = (props: IBankProps, pageName: string) => (props.accounts && props.accounts.length > 0
  ? props.accounts.map((account: IAccountInfo) => ({
    name: account.institutionName,
    type: account.bankType,
    number: account.number,
    finicityId: account.accountid,
    selected: pageName === 'deposit'
      ? (props.depositInfo && props.depositInfo.finicityId === account.accountid) || false
      : (props.debitInfo && props.debitInfo.finicityId === account.accountid) || false
  }))
  : []);

const loadSelectedAccount = (props: IBankProps, pageName: string) => {
  if (pageName === 'deposit' && props.depositInfo?.finicityId) {
    const bank = props.accounts.find((account: IAccountInfo) => account.accountid === props.depositInfo.finicityId);
    return bank ? {
      name: bank.institutionName,
      type: bank.bankType,
      number: bank.number,
      finicityId: bank.accountid,
      selected: true
    } : { selected: false } as any;
  }
  if (pageName === 'debit' && props.debitInfo?.finicityId) {
    const bank = props.accounts.find((account: IAccountInfo) => account.accountid === props.debitInfo.finicityId);
    return bank ? {
      name: bank.institutionName,
      type: bank.bankType,
      number: bank.number,
      finicityId: bank.accountid,
      selected: true
    } : { selected: false } as any;
  }
  return { selected: false } as any;
};

export const HelocBankPage = (props: IBankProps) => {
  const { progress, handleBackSubmit, pageName, handlePageSubmit, applicationId, content } = useContext(HelocContext);
  const [linkedAccounts, setLinkedAccounts] = useState(loadLinkedAccounts(props, pageName) as any);
  const [manualAccounts, setManualAccounts] = useState([] as any);
  const [bankForm, setBankForm] = useState(newBankForm());
  const [loading, setLoading] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(loadSelectedAccount(props, pageName));
  const [addAccount, setAddAccount] = useState(false);
  const [depositAccount, setDepositAccount] = useState(props.depositInfo ? props.depositInfo : { selected: false } as any);
  const [debitAccount] = useState(props.debitInfo ? props.debitInfo : { selected: false } as any);
  const [isFinicity, setFinicity] = useState(false);
  const [finicityError, setFinicityError] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  const [updatedIndex, setUpdatedIndex] = useState<number | undefined>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name: string, setForm: any, form: IBankForm) => {
    setForm({ ...form, [name]: event.target.value });
  };

  useEffect(() => {
    if (pageName === 'debit') {
      setPageLoading(true);
      setTimeout(() => {
        setPageLoading(false);
      }, 1000);
    }

    if (pageName === 'deposit' && depositAccount) {
      setSelectedAccount(depositAccount);
    }
  }, [pageName]);

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>, name: string, setForm: any, form: IBankForm) => {
    if (event.target.value === '' || event.target.value.replace(/\D/g, '') === event.target.value) {
      setForm({ ...form, [name]: event.target.value });
    }
  };

  const handleAddAccount = (form: IBankForm) => {
    setTrySubmit(true);
    if (form.accountNumber && form.name && form.routingNumber.length === 9) {
      const newOrUpdatedAccount = {
        name: form.name,
        routingNumber: form.routingNumber,
        accountNumber: form.accountNumber,
        id: '',
        selected: updatedIndex !== undefined ? manualAccounts[updatedIndex].selected : true
      };

      if (updatedIndex !== undefined) {
        manualAccounts[updatedIndex] = newOrUpdatedAccount;
        if (manualAccounts[updatedIndex]?.selected) {
          setSelectedAccount(newOrUpdatedAccount);
        }
      } else {
        linkedAccounts.map((account: any) => account.selected = false);
        manualAccounts.map((account: any) => account.selected = false);
        setManualAccounts([...manualAccounts, newOrUpdatedAccount]);
        setSelectedAccount(newOrUpdatedAccount);
      }

      handleAddCancel();
    }
  };

  const handleSubmit = async () => {
    setTrySubmit(true);
    if (selectedAccount?.selected) {
      setLoading(true);
      const depositBank = pageName === '' ? selectedAccount : depositAccount;
      const debitBank = pageName === 'debit' ? selectedAccount : debitAccount;
      if (pageName === 'deposit') {
        setDepositAccount(selectedAccount);
      }
      const pageData = `{ 
        page: "${pageName}"
        depositInfo: {
          finicityId: "${depositBank?.finicityId || ''}"
          name: "${depositBank?.name}"
          routingNumber: "${depositBank?.routingNumber || ''}"
          accountNumber: "${depositBank?.accountNumber || depositBank?.number || ''}"
        }
        debitInfo: {
          finicityId: "${debitBank?.finicityId || ''}"
          name: "${debitBank?.name || ''}"
          routingNumber: "${debitBank?.routingNumber || ''}"
          accountNumber: "${debitBank?.accountNumber || depositBank?.number || ''}"
        }
      }`;
      (window as any).digitalData = {
        pageInfo: {
          bankData: {
            manualDeposit: depositBank?.finicityId && depositBank.finicityId !== '',
            manualDebit: debitBank?.finicityId && debitBank.finicityId !== ''
          }
        }
      };
      await handlePageSubmit(pageData);
      setLoading(false);
      setTrySubmit(false);
    }
  };

  const handleBack = async () => {
    setFinicityError('');
    await handleBackSubmit(pageName);
  };

  const handleFinicitySuccess = async (result: any) => {
    setLinkedAccounts(result.accounts.map((account: IAccountInfo) => ({
      name: account.institutionName,
      type: account.bankType,
      number: account.number,
      finicityId: account.accountid,
      selected: false
    })));
    setFinicityError('');
    setFinicity(false);
    setLoading(false);
  };

  const handleFinicityError = async () => {
    setFinicity(false);
    setFinicityError(content.finicity_error_message);
    setLoading(false);
  };

  const accountRowClick = (account: any) => {
    manualAccounts.map((account: any) => account.selected = false);
    linkedAccounts.map((account: any) => account.selected = false);
    setSelectedAccount(account);
    account.selected = true;
  };

  const handleAccountEdit = ({ name, accountNumber, routingNumber }: IBankForm, index: number) => {
    setUpdatedIndex(index);
    setAddAccount(true);
    setBankForm({ name, accountNumber, routingNumber });
    window.scrollTo(0, 0);
  };

  const handleAddCancel = () => {
    setBankForm(newBankForm());
    setAddAccount(false);
    setUpdatedIndex(undefined);
    setTrySubmit(false);
    window.scrollTo(0, 0);
  };

  const getLinkedAccountRow = (linkedAccount: any, index: number) => (
    <div
      className="md:flex ml-8 mb-4 cursor-pointer linkedAccount w-full"
      key={`additionalAccount${index}`}
      onClick={() => accountRowClick(linkedAccount)}
    >
      <div className="flex">
        <div>
          <input type="radio" className="checkbox" checked={linkedAccount.selected} readOnly={true} />
        </div>
        <span className="font-bold md:mr-4">{`${linkedAccount.name} - ${toFirstLetterCapital(linkedAccount.type)}`}</span>
      </div>
      <div>
        <span className="mr-2">Account #</span>
        <span className="font-bold">XXXXX{linkedAccount.number}</span>
      </div>
    </div>
  );

  const getAdditionalAccountRow = (account: any, index: number) => (
    <div
      className="md:flex ml-8 mb-4 cursor-pointer manualAccount"
      key={`additionalAccount${index}`}
      onClick={() => accountRowClick(account)}
    >
      <div className="flex">
        <div>
          <input type="radio" className="checkbox" checked={account.selected} readOnly={true} />
        </div>
        <span className="font-bold md:mr-4">{account.name}</span>
      </div>
      <div>
        <span className="mr-2">Routing #</span>
        <span className="font-bold md:mr-4">{account.routingNumber}</span>
      </div>
      <div>
        <span className="mr-2">Account #</span>
        <span className="font-bold">{hashNotLastFour(account.accountNumber, 'X')}</span>
      </div>
      <div className="m-auto">
        <Button
          className="h-2.5"
          buttonStyle="tertiary"
          buttonSize="small"
          onClick={(e) => {
            e.stopPropagation();
            handleAccountEdit(account, index);
          }}
        >
          Edit
        </Button>
      </div>
    </div>
  );
  const hasError = (name: string, form: IBankForm) => trySubmit && form[name as keyof IBankForm].length <= 0;
  const accountNumberError = trySubmit ? getAccountNumberError(bankForm.accountNumber) : '';

  const renderForm = () => (
    <div>
      <h4 className="font-bold text-xl mt-8 mb-4">{updatedIndex !== undefined
        ? 'Update bank account'
        : content.add_account_label}
      </h4>
      <TextInput
        name="name"
        value={bankForm.name}
        label={content.lender_label}
        onChange={(input) => handleChange(input, 'name', setBankForm, bankForm)}
        hasError={hasError('name', bankForm)}
        helperText={hasError('name', bankForm) ? 'required' : undefined}
        required={true}
        maxLength={25}
      />
      <div className="grid grid-cols-2 space-x-4">
        <TextInput
          name="routingNumber"
          value={bankForm.routingNumber}
          label={content.routing_label}
          onChange={(input) => handleChange(input, 'routingNumber', setBankForm, bankForm)}
          mask={MASKS.ROUTING}
          hasError={trySubmit && (hasError('routingNumber', bankForm) || bankForm.routingNumber.length < 9)}
          helperText={hasError('routingNumber', bankForm) ? 'required' : undefined}
          required={true}
        />
        <TextInput
          name="accountNumber"
          value={bankForm.accountNumber}
          label={content.account_label}
          onChange={(input) => handleNumberChange(input, 'accountNumber', setBankForm, bankForm)}
          hasError={!!accountNumberError}
          helperText={accountNumberError}
          required={true}
          maxLength={17}
          minLength={8}
        />
      </div>
      <div className="flex justify-center md:justify-end mb-8">
        <Button
          buttonStyle="secondary"
          className="!w-full md:!w-48 mr-4"
          onClick={handleAddCancel}
        >Cancel
        </Button>
        <Button
          buttonStyle="primary"
          className="!w-full md:!w-48 saveNewButton"
          onClick={() => { handleAddAccount(bankForm); }}
        >Save
        </Button>
      </div>
    </div>
  );

  return (
    <Form title={content.header} progress={progress}>
      {pageLoading ? (
        <div className="min-h-[420px]">
          <div className="flex items-center justify-center">
            <Loader color="#D13239" className="loader-medium" />
          </div>
        </div>
      )
        : isFinicity
          ? (
            <HelocBankVerification
              handleSuccess={handleFinicitySuccess}
              handleError={handleFinicityError}
              handleBack={() => setFinicity(false)}
              isAdditional={true}
            />
          )
          : (
            <div>
              <div className="min-h-[300px]">
                <p className="mb-4">{content.description}</p>
                {finicityError && (
                  <div className="mb-8">
                    <Alert text={finicityError} type="error" />
                  </div>
                )}
                {
                  !!(linkedAccounts.length || manualAccounts.length) && updatedIndex === undefined
                  && <h4 className="font-bold text-xl mt-8 mb-4">{content.linked_account_header}</h4>

                }
                {
                  linkedAccounts.length > 0 && (
                    <div>
                      {linkedAccounts.map((account: any, index: number) => getLinkedAccountRow(account, index))}
                    </div>
                  )
                }
                {
                  manualAccounts.length > 0 && (
                    <div className="mb-4">
                      {manualAccounts.map((account: any, index: number) =>
                        (updatedIndex === undefined ? (
                          getAdditionalAccountRow(account, index)
                        ) : updatedIndex === index ? (
                          <div key={`account${index}`}>{renderForm()}</div>
                        ) : null))}
                    </div>
                  )
                }
                {!addAccount && (
                  <>
                    <Card
                      className="mb-2 autoVerify flex"
                      role="button"
                      tabIndex={0}
                      onClick={() => setFinicity(true)}
                    >
                      <div>
                        <div
                          className="p-6 flex flex-row items-center justify-between gap-3"
                          role="button"
                          tabIndex={0}
                        >
                          <img
                            style={imageStyles}
                            src="https://images.contentstack.io/v3/assets/bltff9521c11371bfa5/bltaa9f340f54d96329/66420040e25e7dfe1689f473/banking.svg"
                            alt="Banking"
                          />
                          <div className="content-group">
                            <div className="flex justify-between">
                              <span className="flex mb-2 flex-col md:flex-row">
                                <h4 className="font-bold">
                                  {content.auto_title}
                                </h4>
                              </span>
                            </div>
                            <p>{content.auto_description}</p>
                          </div>
                          <FontIcon
                            className="text-black"
                            iconName="chevron-right-large"
                          />
                        </div>
                      </div>
                    </Card>
                    <Button
                      className="font-bold pt-4 !border-0 addEmployment"
                      buttonStyle="tertiary"
                      buttonAttrs={{ disabled: loading }}
                      onClick={() => {
                        setAddAccount(true);
                      }}
                    >
                      <FontIcon
                        iconName="plus-circle"
                        className="text-xl mr-2 !leading-loose"
                      />
                      <span className="text-base">
                        Manually add another account
                      </span>
                    </Button>
                  </>
                )}
                {addAccount && updatedIndex === undefined && renderForm()}
              </div>
              {
                !addAccount && (
                  <div>
                    <div className="md:flex justify-center mt-12 md:space-x-4">
                      <Button
                        buttonStyle="primary"
                        className="!w-full md:!w-60"
                        onClick={handleSubmit}
                        loading={loading}
                        buttonAttrs={{ disabled: loading || !(selectedAccount?.selected) }}
                      >{content.submit_cta}
                      </Button>
                    </div>
                    {pageName !== 'deposit' && (
                      <div className="flex justify-center mt-6 -ml-8 md:ml-0 md:block md:-mt-12">
                        <Button
                          buttonStyle="quaternary"
                          iconPos="left"
                          iconName="chevron-left-large"
                          onClick={handleBack}
                          buttonAttrs={{ disabled: loading }}
                        >
                          Back
                        </Button>
                      </div>
                    )}
                  </div>
                )
              }
            </div>
          )}

    </Form>
  );
};
