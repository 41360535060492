import Button from '@guaranteed-rate/react-components/dist/Button';
import { useContext } from 'react';
import Form from '../../../components/Form/Form';
import { IBasicInfoData, IPropertyResponse } from '../../../config/util/interfaces';
import Card from '../../../components/Card/Card';
import Alert from '../../../components/Alert/Alert';
import { config } from '../../../config/content/config';
import { HelocContext } from '../HelocPage';

interface IDiclosureProps {
  basicInfo: IBasicInfoData;
  propertyInfo: IPropertyResponse;
}

export const HelocDashboardPage = (props: IDiclosureProps) => {
  const { progress, content } = useContext(HelocContext);
  const title = content.header.replace('%NAME', props.basicInfo.name.first);
  const street = props.propertyInfo?.address.street;
  const location = `${props.propertyInfo?.address.city}, ${props.propertyInfo?.address.region} ${props.propertyInfo?.address.postalCode}`;

  const handleSubmit = async () => {
    window.location.href = `${(config.myAccountUrl as any)[(window as any).env]}/okta/login?email=${props.basicInfo.emailId}`;
  };

  return (
    <Form title={title} progress={progress}>
      <div>
        <div className="mb-4">
          <Alert text={content.success_text} type="success" />
        </div>
        <Card>
          <div className="p-4">
            <div className="flex mb-2">
              <span className="mr-4 px-1 font-bold rounded leading-5 text-md loanTag tagSelected">HELOC</span>
              <span className="px-1 rounded font-bold leading-5 text-md loanTag">Closing Scheduled</span>
            </div>
            <h4 className="font-bold text-2xl">{street}</h4>
            <h4 className="font-bold text-2xl mb-4">{location}</h4>
          </div>
        </Card>
        <div className="flex justify-center mt-12">
          <Button
            buttonStyle="primary"
            className="!w-full md:!w-64 nextButton"
            onClick={handleSubmit}
          >Continue to my account
          </Button>
        </div>
      </div>
    </Form>
  );
};
