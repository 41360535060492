import Button from '@guaranteed-rate/react-components/dist/Button';
import { useContext } from 'react';
import Form from '../../../components/Form/Form';
import { IBasicInfoData } from '../../../config/util/interfaces';
import { config } from '../../../config/content/config';
import { HelocContext } from '../HelocPage';

interface ICompleteProps {
  basicInfoData: IBasicInfoData;
}

export const HelocCompletePage = (props: ICompleteProps) => {
  const { progress, content } = useContext(HelocContext);
  // submits the income data to the orchestrator and moves to the next state
  const handleSubmit = async () => {
    window.location.href = `${(config.myAccountUrl as any)[(window as any).env]}/okta/login?email=${props.basicInfoData.emailId}`;
  };

  return (
    <Form title={content.header} progress={progress}>
      <div className="min-h-[300px]">
        <h4>{content.description}</h4>
        <div className="flex justify-center mt-12">
          <Button
            buttonStyle="primary"
            className="!w-full md:!w-64 nextButton"
            onClick={handleSubmit}
          >{content.cta}
          </Button>
        </div>
      </div>
    </Form>
  );
};
