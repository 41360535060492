import Button from '@guaranteed-rate/react-components/dist/Button';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import Form from '../../../components/Form/Form';
import { IPropertyForm } from './HelocPropertyPage';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { PROPERTY_PAGES } from '../../../config/content/constants';

interface IOccupancyAndPurposePageProps {
  content: any;
  helocForm: IPropertyForm;
  loading: boolean;
  progress?: ProgressProps;
  pageName: string;
  isLite?: boolean;
  updateHelocForm: (key: string, value: string) => void;
  handleSelect: (event: string) => void;
  handleBack: () => void;
}
interface radioButtonProps {
  option: radioButtonOptionProps;
}

interface radioButtonOptionProps {
  label: string;
  value: string;
}

const OccupancyAndPurposePage = (props: IOccupancyAndPurposePageProps) => {
  const {
    progress,
    loading,
    helocForm,
    pageName,
    isLite,
    content,
    updateHelocForm,
    handleSelect
  } = props;

  // This renders the options for the occupancy and purpose pages
  const getRadioButtonOptions = (radioButtonOpt: Array<radioButtonProps>) => (radioButtonOpt ? radioButtonOpt.map((item: any) => ({
    name: 'options',
    label: item.option.label,
    value: item.option.value,
    active: false,
  })) : []);

  const occupancyOptions = getRadioButtonOptions(content.occupancy_radio_button);
  const helocPurposeOptions = getRadioButtonOptions(content.purpose_radio_button);
  const helocPropertyTypeOptions = getRadioButtonOptions(content.property_type_radio_button);

  let option = occupancyOptions;
  switch (pageName) {
  case PROPERTY_PAGES.PURPOSE:
    option = isLite ? helocPurposeOptions.filter((item) => item.value !== 'OTHER' && item.value !== 'MAJOR_PURCHASE')
      : helocPurposeOptions.filter((item) => item.value !== 'PERSONAL_REASONS');
    break;

  case PROPERTY_PAGES.OCCUPANCY:
    option = occupancyOptions;
    break;

  case PROPERTY_PAGES.PROPERTY_TYPE:
    option = helocPropertyTypeOptions;
    break;

  default:
    break;
  }

  return (
    <Form title={content?.[pageName].header} progress={progress}>
      <div className="w-full">
        <div className="flex justify-center mb-10 w-full">
          <div className="max-w-md w-full mb-8">
            <RadioButtons
              className="radioButton"
              radios={option}
              isChecked={pageName === 'purpose' && helocForm.purpose === 'OTHER' ? 'OTHER' : ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSelect(event.target.value)}
            />
            {pageName === 'purpose' && helocForm.purpose === 'OTHER' && (
              <div className="flex flex-col justify-center max-w-md w-full">
                <TextInput
                  name="other"
                  label="Other"
                  value={helocForm.otherReason}
                  onChange={(input) => updateHelocForm(input.target.value, 'otherReason')}
                  required={
                    pageName === 'purpose'
                && helocForm.purpose === 'OTHER'
                  }
                />
              </div>
            )}
          </div>
        </div>
        {pageName === 'purpose' && helocForm.purpose === 'OTHER' && (
          <div className="flex justify-center mt-4">
            <Button
              buttonStyle="primary"
              className="!w-full md:!w-48 annualNext"
              onClick={() => handleSelect('')}
              loading={loading}
              buttonAttrs={{ disabled: !helocForm.otherReason }}
            >
              Next
            </Button>
          </div>
        )}
        <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
          <Button
            buttonStyle="quaternary"
            iconPos="left"
            iconName="chevron-left-large"
            onClick={props.handleBack}
            buttonAttrs={{ disabled: loading }}
          >Back
          </Button>
        </div>
      </div>
    </Form>

  );
};

export default OccupancyAndPurposePage;
