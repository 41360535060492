/* eslint-disable max-len */
import { useContext, useEffect, useState } from 'react';
import Form from '../../../components/Form/Form';
import { HelocContext } from '../HelocPage';

const HelocReviewPage = () => {
  const { progress, pageName, handlePageSubmit } = useContext(HelocContext);
  const [apiCount, setApiCount] = useState(1);

  useEffect(() => {
    handleSubmit();
    setApiNextApiCall(30000);
  }, [apiCount]);

  const setApiNextApiCall = (timeout: number) => {
    setTimeout(() => setApiCount(apiCount + 1), timeout);
  };

  const handleSubmit = async () => {
    const pageData = `{ 
      page: "${pageName}"
    }`;
    await handlePageSubmit(pageData);
  };

  return (
    <Form title="Thank you for your application" progress={progress}>
      <div className="min-h-[300px]">
        <p>Thank you for submitting your application! Your application is now under review by our team. You can expect to hear back from us over the next few business days.</p>
      </div>
    </Form>
  );
};

export default HelocReviewPage;
