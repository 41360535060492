export const helocGraphQLRequest = `{
  page
  status
  id
  authRequired
  logoutRequired
  errorMessage
  pageContentJson
  trackingId
  threatMetrixOrgId
  threatMetrixProfilingDomain
  loanOfficerId
  applicationDate
  encompassId
  disablePrequalify
  incomeVerified
  identityVerified
  employmentVerified
  schedulingTime
  isInManualReview
  isInDisasterZone
  mortgageData {
    address
    mortgages {
      lender
      date
      amount
      id
      isManualEntry
      monthlyPayment
      lienType
    }
  }
  pricingData {
    options {
      fee
      terms {
        term
        apr
        estimatedMonthlyPayment
        pricingTermId
        loanAmount
      }
    }
    maximumAmount
    loanAmount
    autoPay
    creditUnion
    selected {
      fee
      term {
        term
        apr
        estimatedMonthlyPayment
        pricingTermId
        loanAmount
      }
    }
  }
  propertyData {
    ownershipType
    structureType
    trustName
    purpose
    otherReason
    propertyType
    address {
      street
      unitNumber
      city
      region
      postalCode
    }
    annualTaxes
    annualInsurance
    annualHoa
    requestedLoanAmount
    estimatedValue
    loanOfficerId
  }
  basicInfoData {
    id
    emailId
    phoneNumber
    dateOfBirth
    name {
      first
      middle
      last
      suffix
    }
    identifier
    maritalStatus
    gender
    ethnicity
    ethnicityDetail
    race
    asianDetail
    islanderDetail
    citizenStatus
    borrowerEntityId
    residenceAddress {
      street
      city
      region
      postalCode
    }
    residenceStartDate
    isActiveOrExMilitary
  }
  incomeData {
    incomeSource
    annualIncome
    otherIncome
    employerName
    employmentStartDate
  }
  employmentData {
    employments {
      employmentType
      employerName
      employerPhone
      jobTitle
      employerAddress {
        street
        city
        region
        postalCode
      }
      startDate
      endDate
      annualPay
      annualCommission
      annualOverTime
      annualBonus
      currentEmploymentIndicator
    }
    otherIncomes {
      incomeType
      monthlyPay
    }
  }
  verificationData {
    incomeAndEmploymentOrderStatus {
      voi
      voieInstant
      voiePayroll
      manualUpload
    }
  }
  depositInfo {
    id
    name
    routingNumber
    finicityId
  }
  debitInfo {
    id
    name
    routingNumber
    finicityId
  }
  accounts {
    number
    name
    bankType
    institutionName
    accountid
  }
  incomes {
    incomeSource
    employerName
    annualPay
    startDate
    endDate
    currentEmployment
  }
  additionalData {
    primaryResidence
    otherOwnership
    borrowingMoney
    otherMortgage
    newCredit
    newLien
    otherDebt
    outstandingJudgment
    delinquentDefault
    lawsuit
    conveyedTitle
    shortSale
    foreclosure
    bankruptcy
    language
  }
  tcpaConfig {
    accountCode
    campaignId
  }
  closingResponse {
    platformUrl
    iframeUrl
    credential
  }
}`;

export const date_regex = /^\d{2}\/\d{2}\/\d{4}$/;

export const MOCK_EMAIL_SUFFIX = 'cy.test@yopmail.com';

export const CHECK_INCORRECT = 'Incorrect';

export const CHECK_CORRECT = 'Correct';

export const PROPERTY_PAGES = Object.freeze({
  PROPERTY: 'property',
  OFFICER: 'officer',
  OCCUPANCY: 'occupancy',
  PURPOSE: 'purpose',
  PARTNERSHIP: 'partnership',
  ANNUAL: 'annualLite',
  PROPERTY_TYPE: 'property_type'
});

export const HELOC_PROPERTY_PAGES = Object.freeze([
  PROPERTY_PAGES.PROPERTY,
  PROPERTY_PAGES.OFFICER,
  PROPERTY_PAGES.OCCUPANCY,
  PROPERTY_PAGES.PURPOSE,
  PROPERTY_PAGES.PARTNERSHIP,
]);

export const HELOC_LITE_PROPERTY_PAGES = Object.freeze([
  PROPERTY_PAGES.ANNUAL,
  PROPERTY_PAGES.OCCUPANCY,
  PROPERTY_PAGES.PROPERTY_TYPE,
  PROPERTY_PAGES.PURPOSE,
]);
export const REDIRECT_TO_LOGIN_ERROR_CODE = '1005';
