/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Dropdown from '@guaranteed-rate/react-components/dist/Dropdown';
import { useCallback, useContext, useEffect, useState } from 'react';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import Form from '../../../components/Form/Form';
import { MASKS } from '../../../config/util/masks';
import { IEmployment, IIncomeEmploymentData, IOtherIncome, IProperty } from '../../../config/util/interfaces';
import { buildLocation, dateDiffInYears, getDateAfterOrBeforeYear, listToGraphQl, loadDate, localeOptions2Digits } from '../../../config/util/common';
import GoogleMapAddress from '../../../components/GoogleMapAddress/GoogleMapAddress';
import CalendarInput from '../../../components/CalendarInput/CalendarInput';
import Banner from '../../../components/Banner/Banner';
import PageLoader from '../../../components/PageLoader/PageLoader';
import { HelocContext } from '../HelocPage';

interface IIncomeProps {
  employmentData: IIncomeEmploymentData;
}

const loadExistingData = (props: IIncomeProps) => {
  if (props.employmentData != null) {
    const form = {
      employments: [],
      otherIncomes: []
    } as IIncomeEmploymentData;
    props.employmentData.employments.map((employment: IEmployment) => {
      employment.monthlyPay = employment.annualPay / 12;
      employment.endDate = employment.endDate || '';
      form.employments.push(employment);
    });
    props.employmentData.otherIncomes.map((otherIncome: IOtherIncome) => {
      form.otherIncomes.push(otherIncome);
    });
    return form;
  }
  return {
    employments: [],
    otherIncomes: []
  } as IIncomeEmploymentData;
};

const newIncomeData = () => ({
  incomeType: '',
  monthlyPay: 0
} as IOtherIncome);

const newLocation = () => ({
  street: ['', ''],
  city: '',
  region: '',
  postalCode: ''
} as IProperty);

const newEmploymentData = () => ({
  employmentType: '',
  employerName: '',
  employerPhone: '',
  jobTitle: '',
  employerAddress: newLocation(),
  startDate: '',
  endDate: '',
  annualPay: 0,
  monthlyPay: 0,
  annualCommission: 0,
  annualOverTime: 0,
  annualBonus: 0,
  currentEmploymentIndicator: false
} as IEmployment);

export const HelocIncomeEmployment = (props: IIncomeProps) => {
  const { progress, handleBackSubmit, pageName, handlePageSubmit, content } = useContext(HelocContext);
  const [helocForm, setHelocForm] = useState(loadExistingData(props));
  const [employmentForm, setEmploymentForm] = useState(newEmploymentData());
  const [incomeForm, setIncomeForm] = useState(newIncomeData());
  const [location, setLocation] = useState({} as IProperty);
  const [employmentAddress, setEmploymentAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [addPage, setAddPage] = useState('');
  const [addIndex, setAddIndex] = useState(0);
  const options = [
    {
      name: 'Yes',
      label: content.history.yes_label,
      value: 'YES',
      active: false
    }, {
      name: 'Yes',
      label: content.history.no_label,
      value: 'NO',
      active: false
    }
  ];
  // Load the income types from content stack
  const employmentTypes = content.employment_type.map((option: any) =>
    ({ value: option.type.value, displayName: option.type.label }));
  const incomeTypes = content.income_type.map((option: any) =>
    ({ value: option.type.value, displayName: option.type.label }));

  const isLast2Years = () => {
    if (helocForm.employments.length > 0) {
      const today = new Date();
      let twoYearsAgo = new Date();
      twoYearsAgo.setFullYear(today.getFullYear() - 2);
      let twoYears = ([] as Array<boolean>);
      for (let i = 0; i < 24; i++) {
        twoYears[i] = false;
      }
      helocForm.employments.map((employment: IEmployment) => {
        let date = loadDate(employment.startDate);
        let endDate = employment.endDate ? loadDate(employment.endDate) : new Date();
        if (endDate >= twoYearsAgo) {
          while (date < endDate) {
            let months = date.getMonth() - twoYearsAgo.getMonth() + (date.getFullYear() - twoYearsAgo.getFullYear()) * 12;
            twoYears[months] = true;
            if (date.getMonth() === 11) {
              date.setMonth(0);
              date.setFullYear(date.getFullYear() + 1);
            } else {
              date.setMonth(date.getMonth() + 1);
            }
          }
        }
      });
      return twoYears.every((val: boolean) => val);
    }
    return false;
  };

  const warningBanner = (helocForm.employments.length > 0 || helocForm.otherIncomes.length > 0) && !isLast2Years()
    ? content.warning_label : '';

  const successBanner = (helocForm.employments.length > 0 || helocForm.otherIncomes.length > 0) && isLast2Years()
    ? content.success_label : '';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name = '', setForm: any, form: any) => {
    setForm({ ...form, [name !== '' ? name : event.target.name]: event.target.value });
  };
  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>, name = '', setForm: any, form: any) => {
    setForm({ ...form, [name !== '' ? name : event.target.name]: parseFloat(event.target.value?.length > 0 ? event.target.value : '0') });
  };

  const handleNext = () => {
    if (isLast2Years()) {
      handleSubmit();
    } else {
      setShowConfirmation(true);
    }
    window.scrollTo(0, 0);
  };

  // submits the income data to the orchestrator and moves to the next state
  const handleSubmit = async () => {
    setTrySubmit(true);
    setLoading(true);
    handleAddCancel();
    const pageData = `{ 
        page: "${pageName}"
        lite: true
        employmentData: {
          employments: ${listToGraphQl(helocForm.employments)}
          otherIncomes: ${listToGraphQl(helocForm.otherIncomes)}
        }
      }`;
    await handlePageSubmit(pageData);
    setLoading(false);
  };

  const onAddressChange = (address: any, setAddress: any) => {
    const property = {} as IProperty;
    property.street = [address.address, address.unitNumber];
    property.unitNumber = address.unitNumber;
    property.city = address.city;
    property.region = address.state;
    property.postalCode = address.zip;
    setLocation(property);
    setAddress(address.formattedAddress);
  };
  const hasError = (name: string, form: any) => (!form[name] || form[name] === 0 || `${form[name]}`.length <= 0);

  const handleAddCancel = () => {
    setEmploymentForm(newEmploymentData());
    setLocation(newLocation());
    setIncomeForm(newIncomeData());
    setEmploymentAddress('');
    setAddPage('');
    setTrySubmit(false);
    window.scrollTo(0, 0);
  };
  const handleDelete = (name: string) => {
    const list = helocForm[name as keyof IIncomeEmploymentData];
    if (list.length > addIndex) {
      list.splice(addIndex, 1);
    }
    setHelocForm({ ...helocForm, [name]: list });
    handleAddCancel();
  };
  const handleAddSubmit = (form: any, name: string) => {
    setTrySubmit(true);
    const keys = Object.keys(form).filter((key: string) => key !== 'employerAddress' && key !== 'annualCommission' && key !== 'annualBonus' && key !== 'annualOverTime' && key !== 'currentEmploymentIndicator' && (!form.currentEmploymentIndicator || key !== 'endDate'));
    if (keys.every((key) => !hasError(key, form)) && (name !== 'employments' || (isPhoneValid() && employmentAddress?.length > 0))) {
      const list = helocForm[name as keyof IIncomeEmploymentData];
      if (addIndex > list.length) {
        list.push(form);
      } else {
        list[addIndex] = form;
      }
      setHelocForm({ ...helocForm, [name]: list });
      handleAddCancel();
    }
  };

  const handleEmploymentSelect = (employment: IEmployment, index: number) => {
    setEmploymentForm(employment);
    setLocation(employment.employerAddress);
    setEmploymentAddress(buildLocation(employment.employerAddress));
    setAddPage('employment');
    setAddIndex(index);
    window.scrollTo(0, 0);
  };

  const handleIncomeSelect = (income: IOtherIncome, index: number) => {
    setIncomeForm(income);
    setAddPage('otherIncomes');
    setAddIndex(index);
    window.scrollTo(0, 0);
  };

  const isPhoneValid = () => employmentForm.employerPhone && employmentForm.employerPhone.length === 10;

  const getIncome = (employment: IEmployment) => {
    const income = (employment.annualBonus || 0) + (employment.annualCommission || 0) + (employment.annualOverTime || 0) + employment.annualPay;
    return income.toLocaleString('en-us', localeOptions2Digits);
  };

  const onHistory = (event: any) => {
    const history = event.target.value;
    if (history === 'NO') {
      handleSubmit();
    } else {
      setShowConfirmation(false);
    }
  };

  const validateStartDate = useCallback(() => {
    if (hasError('startDate', employmentForm)) {
      return 'required';
    }
    if (employmentForm.endDate && dateDiffInYears(new Date(employmentForm.startDate), new Date(employmentForm.endDate)) > 120) {
      return 'Date is too far in the past';
    }

    return '';
  }, [employmentForm.endDate, employmentForm.startDate]);

  useEffect(() => { setEmploymentForm({ ...employmentForm, employerAddress: location }); }, [location]);

  const startDateError: string = validateStartDate();
  return (
    <div>
      { loading
        ? <PageLoader /> : showConfirmation ? (
          <Form title={content.history.header} progress={progress}>
            {
              loading ? (
                <div className="min-h-[420px] flex items-center justify-center">
                  <Loader color="#D13239" className="loader-medium" />
                </div>
              ) : (
                <div className="min-h-[420px] historyPage">
                  <h4 className="mb-8">{content.history.description}</h4>
                  <div>
                    <h4 className="mb-4 font-bold mt-4">{content.same_label}</h4>
                    <RadioButtons
                      className="radioButton grow"
                      radios={options}
                      onChange={onHistory}
                    />
                  </div>
                </div>
              )
            }
          </Form>
        )
          : (
            <Form title={content.header} progress={progress}>
              {
                warningBanner && (
                  <div className="mb-8 warningBanner">
                    <Banner text="" title={warningBanner} type="warning" />
                  </div>
                )
              }
              {
                successBanner && (
                  <div className="mb-8 successBanner">
                    <Banner text="" title={successBanner} type="success" />
                  </div>
                )
              }
              <div className="min-h-[300px]">
                {
                  addPage === '' ? (
                    <div>
                      <h4 className="mb-4">{content.description}</h4>
                      {
                        helocForm.employments.length > 0 && (
                          <div className="mb-8">
                            <h3 className="font-bold">Employment</h3>
                            {
                              helocForm.employments.map((employment: IEmployment, index: number) => (
                                <div
                                  className="rounded-lg border-2 p-4 cursor-pointer mb-4 employmentCard"
                                  key={`employment${index}`}
                                  onClick={() => !loading && handleEmploymentSelect(employment, index)}
                                >
                                  <div className="flex justify-between">
                                    <div className="w-full">
                                      <div className="flex">
                                        <div className="flex flex-col justify-center">
                                          <FontIcon className="text-emerald-500 text-2xl mr-4" iconName="check-tick-circle-filled" />
                                        </div>
                                        <div>
                                          <h4 className="font-bold">{employment.employerName}</h4>
                                          <h4 className="text-sm">Start date:
                                            <span className="font-bold ml-2">{new Date(employment.startDate).toLocaleDateString()}</span>
                                            {employment.endDate && employment.endDate.length > 0 ? (
                                              <span className="ml-4">
                                                End date:
                                                <span className="font-bold ml-2">{new Date(employment.endDate).toLocaleDateString()}</span>
                                              </span>
                                            ) : ''}
                                          </h4>
                                          <h4 className="text-sm">Address:
                                            <span className="font-bold ml-2">{buildLocation(employment.employerAddress)}</span>
                                          </h4>
                                          <h4 className="text-sm">Job Title: <span className="font-bold ml-2">{employment.jobTitle}</span></h4>
                                          <h4 className="text-sm">Total Income: <span className="font-bold ml-2">{getIncome(employment)}</span></h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-col justify-center">
                                      <FontIcon className="font-bold text-2xl" iconName="chevron-right-small" />
                                    </div>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        )
                      }
                      {
                        helocForm.otherIncomes.length > 0 && (
                          <div className="mb-8">
                            <h3 className="font-bold">Other Income</h3>
                            {
                              helocForm.otherIncomes.map((income: IOtherIncome, index: number) => (
                                <div
                                  className="rounded-lg border-2 p-4 cursor-pointer mb-4 otherCard"
                                  key={`employment${index}`}
                                  onClick={() => !loading && handleIncomeSelect(income, index)}
                                >
                                  <div className="flex justify-between">
                                    <div className="w-full">
                                      <div className="flex">
                                        <div className="flex flex-col justify-center">
                                          <FontIcon className="text-emerald-500 text-2xl mr-4" iconName="check-tick-circle-filled" />
                                        </div>
                                        <div>
                                          <h4 className="font-bold">
                                            {incomeTypes.find((type: any) =>
                                              type.value === income.incomeType)?.displayName || income.incomeType}
                                          </h4>
                                          <h4 className="text-sm">Monthly Pay:
                                            <span className="font-bold ml-2">
                                              {income.monthlyPay.toLocaleString('en-us', localeOptions2Digits)}
                                            </span>
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex flex-col justify-center">
                                      <FontIcon className="font-bold text-2xl" iconName="chevron-right-small" />
                                    </div>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        )
                      }
                      <Button
                        className="font-bold pt-4 !border-0 addEmployment"
                        buttonStyle="tertiary"
                        buttonAttrs={{ disabled: loading }}
                        onClick={() => { setAddIndex(helocForm.employments.length); setAddPage('employment'); }}
                      >
                        <FontIcon iconName="plus-circle" className="text-xl mr-2 !leading-loose" />
                        <span className="text-base">{content.add_employment_button}</span>
                      </Button>
                      <div className="linebreak-thin mt-4 mb-4" />
                      <Button
                        className="font-bold pt-4 !border-0 addOther"
                        buttonStyle="tertiary"
                        buttonAttrs={{ disabled: loading }}
                        onClick={() => { setAddIndex(helocForm.otherIncomes.length); setAddPage('other'); }}
                      >
                        <FontIcon iconName="plus-circle" className="text-xl mr-2 !leading-loose" />
                        <span className="text-base">{content.add_income_button}</span>
                      </Button>
                      <div className="linebreak-thin mt-4 mb-4" />
                    </div>
                  )
                    : addPage === 'employment' ? (
                      <div>
                        <Dropdown
                          name="employmentType"
                          value={employmentForm.employmentType}
                          label={content.employment.type_label}
                          onChange={(event) => handleChange(event, 'employmentType', setEmploymentForm, employmentForm)}
                          className="w-full employmentTypeDropdown"
                          options={employmentTypes}
                          hasError={trySubmit && hasError('employmentType', employmentForm)}
                          helperText={trySubmit && hasError('employmentType', employmentForm) ? 'required' : undefined}
                          required={true}
                        />
                        <div className="md:grid md:grid-cols-2 md:space-x-4">
                          <TextInput
                            name="employerName"
                            value={employmentForm.employerName}
                            label={content.employment.name_label}
                            onChange={(event) => handleChange(event, 'employerName', setEmploymentForm, employmentForm)}
                            hasError={trySubmit && hasError('employerName', employmentForm)}
                            helperText={trySubmit && hasError('employerName', employmentForm) ? 'required' : undefined}
                            required={true}
                          />
                          <TextInput
                            name="employerPhone"
                            value={employmentForm.employerPhone}
                            label={content.employment.phone_label}
                            onChange={(event) => handleChange(event, 'employerPhone', setEmploymentForm, employmentForm)}
                            hasError={trySubmit && !isPhoneValid()}
                            helperText={trySubmit && !isPhoneValid() ? 'required' : undefined}
                            required={true}
                            mask={MASKS.PHONE}
                          />
                        </div>
                        <TextInput
                          name="jobTitle"
                          value={employmentForm.jobTitle}
                          label={content.employment.title_label}
                          onChange={(event) => handleChange(event, 'jobTitle', setEmploymentForm, employmentForm)}
                          hasError={trySubmit && hasError('jobTitle', employmentForm)}
                          helperText={trySubmit && hasError('jobTitle', employmentForm) ? 'required' : undefined}
                          required={true}
                        />
                        <GoogleMapAddress
                          name="employerAddress"
                          onChange={(address) => onAddressChange(address, setEmploymentAddress)}
                          value={employmentAddress}
                          addressObj={location}
                          label={content.employment.address_label}
                          type="address"
                          hasError={trySubmit && employmentAddress.length === 0}
                          required={true}
                        />
                        <div className="flex flex-row mb-4">
                          <div>
                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={employmentForm.currentEmploymentIndicator}
                              onChange={() => setEmploymentForm({ ...employmentForm, currentEmploymentIndicator: !employmentForm.currentEmploymentIndicator })}
                            />
                          </div>
                          <h4>
                            <div className="text-sm text-left">{content.employment.current_label}</div>
                          </h4>
                        </div>
                        <div className={employmentForm.currentEmploymentIndicator ? 'w-full' : 'md:grid md:grid-cols-2'}>
                          <div className="w-full">
                            <CalendarInput
                              label={content.employment.start_label}
                              className="startDate"
                              name="startDate"
                              setForm={setEmploymentForm}
                              form={employmentForm}
                              hasError={trySubmit && !!startDateError}
                              maxDate={
                                (!employmentForm.currentEmploymentIndicator && employmentForm.endDate && new Date(employmentForm.endDate))
                              || undefined
                              }
                              helperText={trySubmit ? startDateError : ''}
                            />
                          </div>
                          {
                            !employmentForm.currentEmploymentIndicator && (
                              <CalendarInput
                                label={content.employment.end_label}
                                className="endDate md:ml-4"
                                name="endDate"
                                setForm={setEmploymentForm}
                                form={employmentForm}
                                hasError={trySubmit && hasError('endDate', employmentForm)}
                                helperText={trySubmit && hasError('endDate', employmentForm) ? 'required' : ''}
                                minDate={employmentForm.startDate ? new Date(employmentForm.startDate) : getDateAfterOrBeforeYear(-120)}
                              />
                            )
                          }
                        </div>

                        <div className="md:grid md:grid-cols-2 md:space-x-4">
                          <TextInput
                            name="annualPay"
                            value={`${employmentForm.annualPay || ''}`}
                            label={content.employment.annual_pay_label}
                            onChange={(event) => handleNumberChange(event, 'annualPay', setEmploymentForm, employmentForm)}
                            onBlur={() => {
                              setEmploymentForm({ ...employmentForm,
                                monthlyPay: employmentForm.annualPay / 12
                              });
                            }}
                            mask={MASKS.CURRENCY}
                            hasError={trySubmit && hasError('annualPay', employmentForm)}
                            helperText={trySubmit && hasError('annualPay', employmentForm) ? 'required' : undefined}
                            required={true}
                          />
                          <TextInput
                            name="monthlyPay"
                            value={`${employmentForm.monthlyPay || ''}`}
                            label={content.employment.monthly_pay_label}
                            onChange={(event) => handleNumberChange(event, 'monthlyPay', setEmploymentForm, employmentForm)}
                            onBlur={() => {
                              setEmploymentForm({ ...employmentForm,
                                annualPay: Math.round(employmentForm.monthlyPay * 12)
                              });
                            }}
                            mask={MASKS.CURRENCY}
                          />
                        </div>
                        <TextInput
                          name="annualCommission"
                          value={`${employmentForm.annualCommission || ''}`}
                          label={content.employment.commission_label}
                          onChange={(event) => handleNumberChange(event, 'annualCommission', setEmploymentForm, employmentForm)}
                          mask={MASKS.CURRENCY}
                        />
                        <div className="md:grid md:grid-cols-2 md:space-x-4">
                          <TextInput
                            name="annualOverTime"
                            value={`${employmentForm.annualOverTime || ''}`}
                            label={content.employment.overtime_label}
                            onChange={(event) => handleNumberChange(event, 'annualOverTime', setEmploymentForm, employmentForm)}
                            mask={MASKS.CURRENCY}
                          />
                          <TextInput
                            name="annualBonus"
                            value={`${employmentForm.annualBonus || ''}`}
                            label={content.employment.bonus_label}
                            onChange={(event) => handleNumberChange(event, 'annualBonus', setEmploymentForm, employmentForm)}
                            mask={MASKS.CURRENCY}
                          />
                        </div>
                        <div className="flex justify-between mb-16">
                          <Button
                            buttonStyle="tertiary"
                            className="!w-full md:!w-24"
                            iconName="trash"
                            onClick={() => (handleDelete('employments'))}
                          >Delete
                          </Button>
                          <div className="flex justify-center md:justify-end">
                            <Button
                              buttonStyle="secondary"
                              className="!w-full md:!w-48 mr-4"
                              onClick={handleAddCancel}
                            >Cancel
                            </Button>
                            <Button
                              buttonStyle="primary"
                              className="!w-full md:!w-48 saveButton"
                              onClick={() => handleAddSubmit(employmentForm, 'employments')}
                            >Save
                            </Button>
                          </div>
                        </div>
                      </div>
                    )
                      : (
                        <div>
                          <Dropdown
                            name="incomeType"
                            value={incomeForm.incomeType}
                            label={content.other.income_type_label}
                            onChange={(event) => handleChange(event, 'incomeType', setIncomeForm, incomeForm)}
                            className="w-full incomeTypeDropdown"
                            options={incomeTypes}
                            hasError={trySubmit && hasError('incomeType', incomeForm)}
                            helperText={trySubmit && hasError('incomeType', incomeForm) ? 'required' : undefined}
                            required={true}
                          />
                          <TextInput
                            name="monthlyPay"
                            value={`${incomeForm.monthlyPay || ''}`}
                            label={content.other.monthly_pay_label}
                            onChange={(event) => handleNumberChange(event, 'monthlyPay', setIncomeForm, incomeForm)}
                            mask={MASKS.CURRENCY}
                            hasError={trySubmit && hasError('monthlyPay', incomeForm)}
                            helperText={trySubmit && hasError('monthlyPay', incomeForm) ? 'required' : undefined}
                            required={true}
                          />
                          <div className="flex justify-between mb-16">
                            <Button
                              buttonStyle="tertiary"
                              className="!w-full md:!w-24"
                              iconName="trash"
                              onClick={() => (handleDelete('otherIncomes'))}
                              loading={loading}
                            >Delete
                            </Button>
                            <div className="flex justify-center md:justify-end">
                              <Button
                                buttonStyle="secondary"
                                className="!w-full md:!w-48 mr-4"
                                onClick={handleAddCancel}
                                loading={loading}
                              >Cancel
                              </Button>
                              <Button
                                buttonStyle="primary"
                                className="!w-full md:!w-48 saveButton"
                                onClick={() => handleAddSubmit(incomeForm, 'otherIncomes')}
                                loading={loading}
                              >Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      )
                }

              </div>
              {
                addPage === '' && (
                  <div>
                    <div className="flex justify-center mt-12">
                      <Button
                        buttonStyle="primary"
                        className="!w-full md:!w-48 nextButton"
                        onClick={handleNext}
                        loading={loading}
                        buttonAttrs={{ disabled: loading || (helocForm.employments.length === 0 && helocForm.otherIncomes.length === 0) }}
                      >Next
                      </Button>
                    </div>
                    <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
                      <Button
                        buttonStyle="quaternary"
                        iconPos="left"
                        iconName="chevron-left-large"
                        onClick={() => handleBackSubmit(pageName)}
                        buttonAttrs={{ disabled: loading }}
                      >Back
                      </Button>
                    </div>
                  </div>
                )
              }
            </Form>
          )}

    </div>
  );
};
