/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import Button from '@guaranteed-rate/react-components/dist/Button';
import { IHelocPageResponse, IMortgage, ITerm } from '../../../config/util/interfaces';
import { localeOptions2Digits, toFirstLetterCapital } from '../../../config/util/common';

interface IConfirmationProps {
  handleSubmit: any;
  handleGoToPage: any;
  content: any;
  selectedTerm: ITerm;
  loanAmount: number;
  pageName: string;
  confirmationData?: IHelocPageResponse;
  setShowConfirmation: any;
}

export const HelocConfirmationPage = (props: IConfirmationProps) => {
  const disabled = props.pageName === 'prequalify' && (props.confirmationData?.disablePrequalify || false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    if (!disabled) {
      await props.handleSubmit();
    } else {
      const pageData = `{ 
        page: "${props.pageName}"
        disablePrequalify: ${props.confirmationData?.disablePrequalify || false}
      }`;
      await props.handleSubmit(pageData, false);
    }
    setLoading(false);
  };

  const goToPage = async (page: string) => {
    setLoading(true);
    const pageData = `{ 
        page: "${props.pageName}"
        goToPage: "${page}"
      }`;
    await props.handleGoToPage(pageData, false);
    setLoading(false);
  };

  const handleBack = async () => {
    props.setShowConfirmation(false);
  };

  return (
    <div>
      <div className="p-4 mb-4 rounded-xl border-slate-300 border w-full lg:w-[calc(100%+11rem)] lg:-ml-24 bg-[var(--color-status-info-lightest)]">
        <div className="grid">
          <div>
            {
              disabled
                ? <h3 className="font-bold mb-4">{props.content.confirmation.loan_header}</h3>
                : (
                  <a className="flex font-bold mb-4 cursor-pointer justify-between" id="editLoan" onClick={() => props.setShowConfirmation(false)}>
                    <h3 className="mr-2">{props.content.confirmation.loan_header}</h3>
                    <Button
                      className="h-2.5 edit-button"
                      buttonStyle="tertiary"
                      buttonSize="medium"
                    >
                      Edit
                    </Button>
                  </a>
                )
            }
            <div className="space-y-2">
              <p>{props.content.confirmation.number_label}
                <span className="font-bold ml-2" id="loanAmount">{props.loanAmount.toLocaleString('en-US', localeOptions2Digits)}</span>
              </p>
              <p>{props.content.confirmation.term_label}
                <span className="font-bold ml-2" id="term">{props.selectedTerm.term}</span>
              </p>
              <p>{props.content.confirmation.apr_label}
                <span className="font-bold ml-2">{props.selectedTerm.apr}%</span>
              </p>
              <p>{props.content.confirmation.monthly_payment_label}
                <span className="font-bold ml-2">{props.selectedTerm.estimatedMonthlyPayment.toLocaleString('en-US', localeOptions2Digits)}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 rounded-xl border-slate-300 border w-full lg:w-[calc(100%+11rem)] lg:-ml-24">
        <div className="grid">
          <div>
            {
              disabled
                ? <h3 className="font-bold mb-4 personalHeader">{props.content.confirmation.personal_header}</h3>
                : (
                  <a className="flex font-bold mb-4 cursor-pointer justify-between" id="editPersonal" onClick={() => goToPage('personal')}>
                    <h3 className="mr-2">{props.content.confirmation.personal_header}</h3>
                    <Button
                      className="h-2.5 edit-button"
                      buttonStyle="tertiary"
                      buttonSize="medium"
                    >
                      Edit
                    </Button>
                  </a>
                )
            }
            <div className="space-y-2">
              <p>{props.content.confirmation.name_label}
                <span className="font-bold ml-2" id="name">{`${props.confirmationData?.basicInfoData.name.first} 
                      ${props.confirmationData?.basicInfoData.name.last}`}
                </span>
              </p>
              <p>{props.content.confirmation.dob_label}
                <span className="font-bold ml-2" id="dob">{props.confirmationData?.basicInfoData.dateOfBirth}</span>
              </p>
              <p>{props.content.confirmation.phone_label}
                <span className="font-bold ml-2" id="phone">{props.confirmationData?.basicInfoData.phoneNumber}</span>
              </p>
              <p>{props.content.confirmation.email_label}
                <span className="font-bold ml-2" id="email">{props.confirmationData?.basicInfoData.emailId}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="line mt-4 mb-4" />
        <div className="grid">
          <div>
            {
              disabled
                ? <h3 className="font-bold mb-4">{props.content.confirmation.property_header}</h3>
                : (
                  <a className="flex font-bold mb-4 cursor-pointer justify-between" id="editProperty" onClick={() => goToPage('property')}>
                    <h3 className="mr-2">{props.content.confirmation.property_header}</h3>
                    <Button
                      className="h-2.5 edit-button"
                      buttonStyle="tertiary"
                      buttonSize="medium"
                    >
                      Edit
                    </Button>
                  </a>
                )
            }
            <div className="flex">
              <p className="mr-2">{props.content.confirmation.address_label}</p>
              <p className="font-bold">{
                [
                  props.confirmationData?.propertyData.address.street[0],
                  props.confirmationData?.propertyData.address.street[1],
                  props.confirmationData?.propertyData.address.city,
                  props.confirmationData?.propertyData.address.region,
                  props.confirmationData?.propertyData.address.postalCode
                ].filter(Boolean).join(', ')
              }
              </p>
            </div>
          </div>
        </div>
        <div className="line mt-4 mb-4" />
        <div className="grid">
          <div>
            {
              disabled
                ? <h3 className="font-bold mb-4">{props.content.confirmation.income_header}</h3>
                : (
                  <a className="flex font-bold mb-4 cursor-pointer justify-between" id="editIncome" onClick={() => goToPage('income')}>
                    <h3 className="mr-2">{props.content.confirmation.income_header}</h3>
                    <Button
                      className="h-2.5 edit-button"
                      buttonStyle="tertiary"
                      buttonSize="medium"
                    >
                      Edit
                    </Button>
                  </a>
                )
            }
            <div className="space-y-2">
              <p>{props.content.confirmation.income_label}
                <span className="font-bold ml-2" id="income">{props.confirmationData?.incomeData.annualIncome.toLocaleString('en-US', localeOptions2Digits)}</span>
              </p>
              <p>{props.content.confirmation.income_type_label}
                <span className="font-bold ml-2" id="incomeType">{toFirstLetterCapital(props.confirmationData?.incomeData.incomeSource || '')}</span>
              </p>
              {/* <p>{props.content.confirmation.other_income_label}
                <span className="font-bold ml-2" id="otherIncome">{props.confirmationData?.incomeData.otherIncome.toLocaleString('en-US', localeOptions2Digits)}</span>
              </p> */}
            </div>
          </div>
        </div>
        <div className="line mt-4 mb-4" />
        <div>
          {
            disabled
              ? <h3 className="font-bold mb-4">{props.content.confirmation.lien_header}</h3>
              : (
                <a className="flex font-bold mb-4 cursor-pointer justify-between" id="editMortgage" onClick={() => goToPage('mortgage')}>
                  <h3 className="mr-2">{props.content.confirmation.lien_header}</h3>
                  <Button
                    className="h-2.5 edit-button"
                    buttonStyle="tertiary"
                    buttonSize="medium"
                  >
                    Edit
                  </Button>
                </a>
              )
          }
          {
            props.confirmationData?.mortgageData.mortgages.map((mortage: IMortgage, index: number) => (
              <div className="flex mb-2" key={`lien${index}`}>
                <p className="mr-4">{props.content.confirmation.lender_label}
                  <span className="font-bold ml-2">{mortage.lender}</span>
                </p>
                <p>{props.content.confirmation.date_label}
                  <span className="font-bold ml-2">{mortage.date}</span>
                </p>
              </div>
            ))
          }

        </div>
      </div>
      <div className="flex justify-center mt-12">
        <Button
          buttonStyle="primary"
          className="!w-full md:!w-48 confirmButton"
          onClick={handleSubmit}
          loading={loading}
          buttonAttrs={{ disabled: loading }}
        >Confirm
        </Button>
      </div>
      {
        !disabled && (
          <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
            <Button
              buttonStyle="quaternary"
              iconPos="left"
              iconName="chevron-left-large"
              onClick={handleBack}
              buttonAttrs={{ disabled: loading }}
            >Back
            </Button>
          </div>
        )
      }
    </div>
  );
};
