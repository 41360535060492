/* eslint-disable max-len */
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { Fragment } from 'react';
import { IAddressForm, IAddressResponse, ILoanOfficer } from './interfaces';
import { scriptsLoaded } from '../../App';
import { log } from './logger';
import { normalizeSearchParam } from '../../pages/Prefi/prefiCommon';

/**
 * @description function to get year difference for given two dates
 * @param dateold date
 * @param datenew date
 * @returns number
 */
export const dateDiffInYears = (dateold: Date, datenew: Date) => {
  let ynew = datenew.getFullYear();
  let mnew = datenew.getMonth();
  let dnew = datenew.getDate();
  let yold = dateold.getFullYear();
  let mold = dateold.getMonth();
  let dold = dateold.getDate();
  let diff = ynew - yold;
  if (mold > mnew) diff--;
  else if (mold === mnew) {
    if (dold > dnew) diff--;
  }
  return diff;
};

/**
 * @description function to get difference of days for given two dates
 * @param startDate date
 * @param endDate
 * @returns number
 */
export const dateDiffInDays = (startDate: Date, endDate: Date) => {
  const date1: any = new Date(startDate);
  const date2: any = new Date(endDate);
  const diffTime = date2 - date1;
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

/**
 * @description function to get past or future date as per the given year
 * @param years number: for past year use eg -120 for future year use eg 120
 * @param date date from where want future or past date
 * @returns date
 */
export const getDateAfterOrBeforeYear = (years: number, date: Date = new Date()): Date => {
  date.setFullYear(date.getFullYear() + (years));
  return date;
};

/**
 * @function setLoCookieFromQueryString
 * @description function to update the LO cookie to the value found in the query string
 * @param {*} loid
 */
export const setLoCookie = (loid: string) => {
  Cookies.set('loId', loid, { expires: 365, domain: '.rate.com', path: '/' });
  if ((window as any).env === 'dev') {
    Cookies.set('loId', loid, { expires: 365, path: '/' });
  }
};

/**
 * @function getCookiedLO
 * @description function to get cookied lo
 *
 * @returns {Number|String|Undefined} id for cookied lo
 */
export const getLoCookie = () => Cookies.get('loId');

export const validateEmail = (email: string) => /^[A-z0-9._\'%+-]+@[A-z0-9.-]+\.[A-z]{2,}$/.test(email)
  // eslint-disable-next-line max-len
  && /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

/**
 * @description format phone number
 * @param phoneNumberString string
 * @returns string '(123) 456-7890'
 */
export const formatPhoneNumber = (phoneNumberString: string) => {
  let cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    // let intlCode = (match[1] ? '+1 ' : '');
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

// eslint-disable-next-line max-len
export const renderParagraph = (rawText: string, classes = '') => rawText.split('\n').map((text: string, index: number) => <p key={`paragraph${index}`} className={classes}>{text}</p>);

/**
 * @description format phone number
 * @param phoneNumber string
 * @returns string '123-456-7890'
 */
export const formatPhoneDashes = (phoneNumber: string) => (
  `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`);

export const timeRenderer = ({ minutes, seconds, completed }: any) => {
  if (completed) {
    // Render a completed state
    return <span>0:0</span>;
  }
  // Render a countdown
  return <span>{minutes}:{seconds}</span>;
};

export const localeOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  style: 'currency',
  currency: 'USD'
} as any;

export const removeDuplicates = () => {
  let index = 0;
  const tags = [] as Array<any>;
  while (index < document.head.children.length) {
    const item = document.head.children?.item(index) as any;
    const name = `${item?.tagName}src${item?.src || ''}href${item?.href || ''}`
      + `name${item?.name || ''}text${item?.textContent?.substring(0, Math.min(50, item.textContent.length)) || ''}`;
    const exists = tags.find((tag: any) => tag === name);
    if ((item?.tagName.toLowerCase() === 'style' || item?.tagName.toLowerCase() === 'script') && exists && item) {
      document.head.removeChild(item);
    } else {
      index++;
      tags.push(name);
    }
  }
};

export const loadScript = (url: string) => {
  loadScriptFunction(url, null);
};
export const loadScriptFunction = (url: string, onLoad: any) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  if (onLoad) {
    script.onload = () => { onLoad(); scriptsLoaded.push(url); };
  } else {
    script.onload = () => scriptsLoaded.push(url);
  }
  if (scriptsLoaded.includes(url)) {
    onLoad();
  } else {
    document.body.appendChild(script);
  }
};

export const fetchFspPageContent = async (page: string) => {
  const mutation = {
    query:
      `query {
        fetchFspContent(page: ${page}) {
            pageContentJson
        }
      }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Query "fetchFspContent" ${JSON.stringify({ page })}`, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.fetchFspContent) {
      if (data.data.fetchFspContent.pageContentJson !== '') {
        const contentData = [] as Array<string>;
        data.data.fetchFspContent.pageContentJson.map((entry: string) => contentData.push(JSON.parse(entry)));
        log({ message: `Query "fetchFspContent" was successful ${JSON.stringify({ page, duration })}`, level: 'info', requestId: uuid });
        return { content: contentData[0] };
      }
    }
  } catch (e) {
    log({ message: `Query "fetchFspContent" failed due to exception ${JSON.stringify({ page })}`, level: 'error', requestId: uuid });
    console.error(e);
  }
  const duration = getTimeDiff(startTime, performance.now());
  log({ message: `Query "fetchFspContent" failed ${JSON.stringify({ page, duration })}`, level: 'error', requestId: uuid });
  return { content: 'error' };
};

export const fetchPageContent = async (page: string) => {
  const mutation = {
    query:
      `query {
        fetchSameDayEligibilityContent(page: ${page}) {
          content {
            pageContentJson
          }
          tcpaConfig {
            accountCode
            campaignId
          }
        }
      }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
    },
    body: JSON.stringify(mutation)
  });
  try {
    log({ message: `Query "fetchSameDayEligibilityContent" ${JSON.stringify({ page })}`, level: 'info', requestId: uuid });
    const data = await resp.json();
    if (data?.data?.fetchSameDayEligibilityContent) {
      if (data.data.fetchSameDayEligibilityContent.content.pageContentJson !== '') {
        log({ message: `Query "fetchSameDayEligibilityContent" was successful ${JSON.stringify({ page })}`, level: 'info', requestId: uuid });
        const contentData = [] as Array<string>;
        data.data.fetchSameDayEligibilityContent.content.pageContentJson.map((entry: string) => contentData.push(JSON.parse(entry)));
        return { content: contentData[0], tcpaConfig: data.data.fetchSameDayEligibilityContent.tcpaConfig };
      }
      loadScript(`https://create.lidstatic.com/campaign/${data.data.fetchSameDayEligibilityContent.tcpaConfig.campaignId}.js?snippet_version=2`);
    } else {
      log({ message: `Query "fetchSameDayEligibilityContent" failed ${JSON.stringify({ page })}`, level: 'error', requestId: uuid });
      return { content: 'error' };
    }
  } catch (e) {
    log({ message: `Query "fetchSameDayEligibilityContent" failed due to exception ${JSON.stringify({ page })}`, level: 'error', requestId: uuid });
    console.log(e);
    return { content: 'error' };
  }
};

export const getEncompassIdData = async (applicationId: string) => {
  const mutation = {
    query:
      `query {
            getEncompassIdData(applicationId: "${applicationId}")
                {
                  encompassId
                }
        }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Query "getEncompassIdData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getEncompassIdData) {
      log({ message: `Query "getEncompassIdData was successful" ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data.data.getEncompassIdData };
    }
    log({ message: `Query "getEncompassIdData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Query "getEncompassIdData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

export const localeOptions2Digits = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  style: 'currency',
  currency: 'USD'
} as any;

export const toFirstLetterCapital = (inputString: string) => {
  const lowercase = inputString.replaceAll('_', ' ').replaceAll('-', ' ').toLowerCase();
  return lowercase.split(' ').map((part: string) => `${part[0].toUpperCase()}${part.slice(1)}`).join(' ');
};

export const hashNotLastFour = (inputString: string, replaceCharacer: string) =>
  `${inputString.slice(0, inputString.length - 4).replaceAll(/./g, replaceCharacer)}${inputString.slice(inputString.length - 4)}`;

export const isPhoneValid = (phone: string) => {
  const badPhoneVals = ['12345', '800', '888', '833', '8669347283'];
  let uniqueChars = phone.match(/(.)(?!\1)/g);
  if (phone.length < 10) {
    return false;
  }
  if (badPhoneVals.some((v) => phone.startsWith(v))) {
    return false;
  }
  if (uniqueChars && uniqueChars.length < 2) {
    return false;
  }
  return true;
};

export const isNumeric = (str: string) => /^\d+$/.test(str);

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export const fetchContent = async (fspPageName: string) => {
  const data = await fetchFspPageContent(fspPageName);
  return data?.content;
};

export const getKeyByValue = (obj: object, val: string) => {
  if (!obj) {
    return '';
  }
  const find = Object.keys(obj).find((key: string) => (obj as any)[key] === val);
  return find ? find.toUpperCase() : '';
};

export const loadAddressData = (addressData?: IAddressResponse) => {
  if (addressData) {
    return {
      address: addressData.street[0],
      unitNumber: addressData.unitNumber || addressData.street.length > 1 ? addressData.street[1] : '',
      city: addressData.city,
      state: addressData.region,
      zip: addressData.postalCode,
    } as IAddressForm;
  }
  return {
    address: '',
    unitNumber: '',
    city: '',
    state: '',
    zip: '',
  } as IAddressForm;
};

export const buildLocation = (addressData: IAddressResponse) => {
  if (addressData) {
    return `${addressData.street[0]} ${addressData.city} ${addressData.region} ${addressData.postalCode}`;
  }
  return '';
};

/**
 * @description convert saved date string to date object
 * @param startDate string
 * @returns Date
 */
export const loadDate = (date: string) => {
  let dataObj = new Date();
  const index1 = date.indexOf('-');
  const index2 = date.lastIndexOf('-');
  dataObj.setFullYear(parseInt(date.substring(0, index1)),
    parseInt(date.substring(index1 + 1, index2)) - 1,
    parseInt(date.substring(index2 + 1)));
  return dataObj;
};

export const objectToGraphQl = (obj: any): string => `{ ${Object.keys(obj).map((key: string) => {
  const val = obj[key as keyof any];

  if (val === null || val === undefined) {
    return '';
  }
  if (typeof val === 'string') {
    return `${key}: "${obj[key as keyof any]}"`;
  }
  if (typeof val === 'number' || typeof val === 'boolean') {
    return `${key}: ${obj[key as keyof any]}`;
  }
  if (Array.isArray(val)) {
    return `${key}: ${JSON.stringify(obj[key])}`;
  }
  return `${key}: ${objectToGraphQl(obj[key as keyof any])}`;
})} }`;

export const listToGraphQl = (list: any) => `[
  ${list.map((item: any) => objectToGraphQl(item))}
]`;

type RichTextTag = '<RL>' | '<RB>' | '<RN>'; // tags must be 2 characters in length, excluding angle brackets
type RichTextType = 'link' | 'bold' | 'newline' | 'text';

const richTextMap: Record<RichTextTag, RichTextType> = {
  '<RL>': 'link',
  '<RB>': 'bold',
  '<RN>': 'newline'
};

interface RichTextPart {type: RichTextType; text: string}

interface FormatRichTextConfig {
  linkClasses: string;
}

const FORMAT_RICH_TEXT_CONFIG_DEFAULTS: FormatRichTextConfig = {
  linkClasses: 'underline tertiary cursor-pointer',
};

export const formatRichText = (key: string, text: string, content: any, configInput: FormatRichTextConfig = {
  linkClasses: 'underline tertiary cursor-pointer'
}) => {
  const config: FormatRichTextConfig = {
    ...FORMAT_RICH_TEXT_CONFIG_DEFAULTS,
    ...configInput
  };

  const parts: Array<RichTextPart> = [];
  while (text.includes('<R')) {
    const firstIndex = text.indexOf('<R');
    parts.push({ text: text.substring(0, firstIndex), type: 'text' });
    const tag: RichTextTag = text.substring(firstIndex, firstIndex + 4) as RichTextTag;
    const type = richTextMap[tag];
    text = text.substring(firstIndex + 4);
    const secondIndex = text.indexOf('<R');
    parts.push({ text: text.substring(0, secondIndex), type });
    text = text.substring(secondIndex + 4);
  }
  parts.push({ text, type: 'text' });
  return (
    <div>
      {
        parts.map((part: RichTextPart, index: number) => {
          if (part.type === 'text') {
            return <span key={`${key}text${index}`}>{part.text}</span>;
          }
          if (part.type === 'link') {
            return (
              <a
                key={`${key}link${index}`}
                href={content[part.text]?.href}
                target="_blank"
                rel="noreferrer noopener"
                className={config.linkClasses}
              >
                {content[part.text]?.title}
              </a>
            );
          }
          if (part.type === 'bold') {
            return <span key={`${key}text${index}`} className="font-bold">{part.text}</span>;
          }
          if (part.type === 'newline') {
            return <Fragment key={`${key}text${index}`}><br /><span>{part.text}</span></Fragment>;
          }
        })
      }
    </div>
  );
};

export const cleanObject = (obj: any) => {
  for (let propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};

export const isMobile = (): boolean => {
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)) {
    return true;
  }

  return false;
};

export const percentageCalculator = (maxVal: number, currentVal: number): string => {
  const percentage = (currentVal * 100) / maxVal;
  return percentage.toString();
};

export const buildMap = () => {
  const map = {} as any;
  const a = 'a'.charCodeAt(0);
  for (let i = 0; i < 26; i++) {
    map[String.fromCharCode(a + i)] = [];
  }
  return map;
};
/**
 * @description format given date with hyphen(-)
 * @param date Date
 * @returns string (yyyy-mm-dd)
 */
export const formatDate = (date: Date) => {
  const formattedDate = `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}`;
  return formattedDate;
};

/**
 * @description format given date with shash(/)
 * @param date Date
 * @returns string (mm-dd-yyyy)
 */
export const formatDateSlashes = (date: Date) => {
  const formattedDate = `${(`0${date.getMonth() + 1}`).slice(-2)}/${(`0${date.getDate()}`).slice(-2)}/${date.getFullYear()}`;
  return formattedDate;
};

export const retrieveStringEquivalent = (category: any, comparitiveObject: any) => {
  let EquivalentValArray = [];
  for (let key in category) {
    if (category.hasOwnProperty(key)) {
      let value = category[key.toLowerCase()];
      const newComparativeObj = comparitiveObject ? Object?.fromEntries(
        Object?.entries(comparitiveObject).map(([k, v]) => [k.toLowerCase(), v])
      ) : {};
      const equivalentData = (newComparativeObj[key.toLowerCase()] ? value : '');
      if (equivalentData !== '') {
        EquivalentValArray.push(equivalentData);
      }
    }
  }
  return EquivalentValArray;
};

export const formatLoanOfficers = (data: Array<any>) => data.map((lo: any) => ({
  displayName: lo.name,
  employeeId: lo.id
} as ILoanOfficer));

export const fetchLoanOfficers = async (setFetchLoData: any, setLoanOfficers: any) => {
  setFetchLoData(true);
  const mutation = {
    query:
      `{
          fetchYextLOs(tenant: "${(window as any).tenantId}") {
                name
                id
            }
        }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
      'X-Request-ID': uuid,
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    if (data) {
      setLoanOfficers(data?.data?.fetchYextLOs?.map((lo: any) => ({ displayName: lo.name, employeeId: lo.id } as ILoanOfficer)));
      setFetchLoData(false);
    } else {
      console.error('Error retrieving data. No loan officers found.');
    }
  } catch (e) {
    console.error('Error retrieving loan officers');
  }
};

// Below regex is used for removing quotes from the keys in the object to support GraphQL payload structure
export const formatAsGraphQLPaylod = (normalJson: any) => JSON.stringify(normalJson).replace(/"(\w+)"\s*:/g, '$1:');

export const isNullEmptyKey = (parent: any, key: string | number, attribute: string) => !parent || parent.length <= 0 || !parent[key] || !parent[key][attribute];

// Below regex is used for extracting the code number present as digits inside a string input
export const getCode = (input: string) => input?.match(/\d+/)?.[0]?.toString() ?? '';

export const setCustomErrorBasedOnResponse = (data: any, errorTypes: any): string =>
  (((getCode(data.errors)?.length > 0) && (errorTypes.map((e: any) => e.status).indexOf(getCode(data.errors)) >= 0))
    ? errorTypes[errorTypes.map((e: any) => e.status).indexOf(getCode(data.errors))]?.message
    : errorTypes[errorTypes.map((e: any) => e.status).indexOf('Others')]?.message);

export const getApplicationIdFromException = (data: any): string => {
  const applicationTextToSearch = 'application id - ';
  const applicationIdLabelLength = applicationTextToSearch.length;
  const applicationIdValueLength = 36;
  const startPosition = data.errors.indexOf(applicationTextToSearch) + applicationIdLabelLength;
  const endPosition = startPosition + applicationIdValueLength;
  return data.errors.substring(startPosition, endPosition);
};

// error as per the date of birth
export const getDOBErrorText = (birthday: string): string => {
  if (birthday.length === 0) return 'required field';
  if (birthday.length < 10) return 'Please enter a valid date';

  if (dateDiffInYears(new Date(birthday), new Date()) < 18) {
    return 'You must be at least 18 years old.';
  }

  if (dateDiffInYears(new Date(birthday), new Date()) > 120) {
    return 'Date is too far in the past.';
  }

  return '';
};

export const getDateErrorText = (inputDate: string, compareDate: Date = new Date()): string => {
  if (inputDate.length === 0) return 'required';
  if (inputDate.length < 10) return 'Please enter a valid date';

  if (dateDiffInYears(new Date(inputDate), compareDate) > 120) {
    return 'Date is too far in the past.';
  }

  return '';
};

// RegEx for finding and inserting hyphen between specific digits
export const getDigitsInSSNFormat = (data: any): string => data.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');

/**
 * @description format the given number to m or ms
 * @param duration number
 * @returns string
 */
export const getTime = (duration: number): string => (duration > 18000
  ? `${parseFloat((duration / 1000).toFixed(3))} s`
  : `${parseFloat(duration.toFixed(3))} ms`);

/**
 * @description get the time diff of given two ms.
 * @param t1: number
 * @param t2: number
 * @returns string
 */
export const getTimeDiff = (t1: number, t2: number): string => getTime(t2 - t1);

export const getAccountNumberError = (accountNumber: string): string => {
  if (!accountNumber) {
    return 'required';
  }

  if (accountNumber.length < 5) {
    return 'account number is too short';
  }

  return '';
};

export const logoutHeloc = () => {
  Cookies.remove('authToken');
  window.location.href = `${window.location.origin}/gateway/logout-heloc-user?tenant-id=${(window as any).tenantId.toLowerCase()}`;
};

export const redirectToMyALogin = () => {
  Cookies.remove('matc');
  const urlParams = new URLSearchParams(window.location.search);
  const invitationNumberParam = normalizeSearchParam(urlParams.get('invitationNumber'));
  const loanIdParam = normalizeSearchParam(urlParams.get('loanId'));
  const oldLoanNumberParam = normalizeSearchParam(urlParams.get('oldLoanNumber'));
  window.location.href = `${
      window.location.origin
    }/gateway/authorize-mortgage-user?invitationNumber=${invitationNumberParam}&loanId=${loanIdParam}&oldLoanNumber=${oldLoanNumberParam}&tenant-id=${(
      window as any
    ).tenantId.toLowerCase()}`;
};

export const isAlphaNumeric = (str: string) => /^[a-z0-9]+$/.test(str);
