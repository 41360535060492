import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import { useState, useContext } from 'react';
import Form from '../../../components/Form/Form';
import { IAdditionalInfo } from '../../../config/util/interfaces';
import { IPropertyForm } from '../HelocPropertyPage/HelocPropertyPage';
import Banner from '../../../components/Banner/Banner';
import { HelocContext } from '../HelocPage';

interface IOwnershipProps {
  additionalInfo: IAdditionalInfo | IPropertyForm;
  setAdditionalInfo: any;
  loading: boolean;
  setLoading: any;
  handleSubmit: any;
  handleBackSubmit: any;
}

export const HelocOwnershipPage = (props: IOwnershipProps) => {
  const { progress, content, isLite, pageName } = useContext(HelocContext);
  const [trySubmit, setTrySubmit] = useState(false);
  const mainPage = 'property';

  // load the ownership types from content stack
  const options = content.ownership_radio_button.map((item: any) => ({
    name: 'options',
    label: item.option.label,
    value: item.option.value,
    active: false
  }));

  // move to the next screen
  const handleSubmit = async () => {
    setTrySubmit(true);
    if (props.additionalInfo.ownershipType !== ''
     && (props.additionalInfo.ownershipType !== 'REVOCABLE_TRUST' || props.additionalInfo.trustName !== '')) {
      props.setLoading(true);
      const pageData = `{ 
        page: "${mainPage}"
        ${isLite ? 'lite: true' : ''}
      }`;
      await props.handleSubmit(pageData, false);
      props.setLoading(false);
    }
  };

  // save the ownership data to the global additional info object
  const handleSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    props.additionalInfo.trustName = '';
    props.setAdditionalInfo({ ...props.additionalInfo, trustName: '', ownershipType: event.target.value });
  };

  return (
    <Form title={content.ownership.header} progress={progress}>
      <div className="min-h-[300px]">
        <div className="flex justify-center w-full">
          <div className="w-full max-w-md">
            {props.additionalInfo.ownershipType === 'IRREVOCABLE_TRUST' && (
              <Banner
                className="border-2 mt-4 mb-4 irrevocableTrust"
                text=""
                title={content.ownership.warning_message}
                type="error"
                icon="warning-triangle"
              />
            )}
            <RadioButtons
              className="radioButton"
              radios={options}
              disabled={props.loading}
              onChange={handleSelect}
              isChecked={props.additionalInfo.ownershipType}
            />
            {
              props.additionalInfo.ownershipType === 'REVOCABLE_TRUST' && (
                <TextInput
                  name="trust"
                  value={props.additionalInfo.trustName}
                  label={content.ownership.trust_label}
                  onChange={(event: any) => props.setAdditionalInfo({ ...props.additionalInfo, trustName: event.target.value })}
                  hasError={trySubmit && props.additionalInfo.ownershipType === 'REVOCABLE_TRUST'
                          && props.additionalInfo.trustName === ''}
                  helperText={trySubmit && props.additionalInfo.ownershipType === 'REVOCABLE_TRUST'
          && props.additionalInfo.trustName === '' ? 'required' : undefined}
                  className="mt-4"
                  required={true}
                />
              )
            }
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-12">
        <Button
          buttonStyle="primary"
          className="!w-full md:!w-48 nextButton"
          onClick={handleSubmit}
          loading={props.loading}
          buttonAttrs={{
            disabled:
              props.loading
              || props.additionalInfo.ownershipType === ''
              || props.additionalInfo.ownershipType === 'IRREVOCABLE_TRUST',
          }}
        >Next
        </Button>
      </div>
      <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
        <Button
          buttonStyle="quaternary"
          iconPos="left"
          iconName="chevron-left-large"
          onClick={() => props.handleBackSubmit(pageName)}
          buttonAttrs={{ disabled: props.loading }}
        >Back
        </Button>
      </div>
    </Form>
  );
};
