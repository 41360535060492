import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import RadioButtons, { useRadio } from '@guaranteed-rate/react-components/dist/RadioButtons';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import Form from '../../../components/Form/Form';
import {
  IAddressForm,
  IBasicInfoData,
  IPersonalForm,
  IPropertyResponse,
  ITcpaData,
  IThreatMetrixData,
} from '../../../config/util/interfaces';
import {
  formatDate,
  formatRichText,
  loadAddressData,
  loadScriptFunction,
  validateEmail
} from '../../../config/util/common';
import { MASKS } from '../../../config/util/masks';
import HelocBorrowerAddress from '../HelocBorrowerAddress/HelocBorrowerAddress';
import Alert from '../../../components/Alert/Alert';
import { useScript } from '../../../config/hooks/useScript';
import { config } from '../../../config/content/config';
import { HelocContext } from '../HelocPage';

interface IPersonalProps {
  basicInfoData: IBasicInfoData;
  propertyData: IPropertyResponse;
  tcpaData: ITcpaData;
  isDisaster: boolean;
  showNameMismatchWarning?: boolean;
  setShowNameMismatchWarning?: (value: boolean) => void;
  threatMetrixData: IThreatMetrixData;
  threatMetrix: string;
  setThreatMetrix: any;
}

export interface IBorrowerAddressForm {
  residenceStartDate: string;
  residenceAddress: IAddressForm;
}

// loads existing personal data
const loadExistingData = (props: IPersonalProps) => {
  if (props.basicInfoData != null) {
    return {
      firstName: props.basicInfoData.name.first,
      middleName: props.basicInfoData.name.middle,
      lastName: props.basicInfoData.name.last,
      email: props.basicInfoData.emailId,
      phoneNumber: props.basicInfoData.phoneNumber,
      suffix: props.basicInfoData.name.suffix,
    } as IPersonalForm;
  }
  return {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    suffix: ''
  } as IPersonalForm;
};
const options = [
  {
    name: 'yes',
    label: 'Yes',
    value: 'Yes',
    active: false
  },
  {
    name: 'no',
    label: 'No',
    value: 'No',
    active: false
  }
];

const loadBorrowerAddressData = (basicInfoData: IBasicInfoData) =>
  ({
    residenceStartDate: basicInfoData?.residenceStartDate ? formatDate(new Date(basicInfoData.residenceStartDate)) : '',
    residenceAddress: loadAddressData(basicInfoData?.residenceAddress),
  } as IBorrowerAddressForm);

const loadCurrentPage = (isLite: boolean, pageName: string, back: string, setBack: any, addressForm: IBorrowerAddressForm) => {
  if (isLite) {
    return pageName;
  }
  if (back) {
    setBack('');
    return pageName;
  }
  if (addressForm.residenceStartDate === '' && window.location.href.indexOf('address') === -1) {
    return 'address';
  }
  return pageName;
};

export const HelocPersonalPage = (props: IPersonalProps) => {
  const { progress, back, setBack, handleBackSubmit, pageName, handlePageSubmit, content, isLite } = useContext(HelocContext);
  const [helocForm, setHelocForm] = useState(loadExistingData(props));
  const [borrowerAddressForm, setBorrowerAddressForm] = useState(loadBorrowerAddressData(props.basicInfoData));
  const [loading, setLoading] = useState(false);
  const [tcpaChecked, setTcpaChecked] = useState(false);
  const [electronicChecked, setElectronicChecked] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);
  const [tcpaLoad, setTcpaLoad] = useState(false);
  const onTcpaLoad = () => setTcpaLoad(true);
  const [threatMetrixProfiling, setThreatMetrixProfiling] = useState(props.threatMetrix);
  const [militaryService, setMilitaryService] = useState(!isLite && back === 'ssnPage');
  const { updateCheckedValue, isChecked } = useRadio();
  const [militaryServiceStatus, setMilitaryServiceStatus] = useState<boolean>();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(loadCurrentPage(isLite, pageName, back, setBack, borrowerAddressForm));
  useScript(`${config.threatMetrixConfig.path}threatMetrixToolkit.js`, false, () => setScriptLoaded(true));

  // Load the threat metrix code to prevent fraud
  useEffect(() => {
    // eslint-disable-next-line
    if (!isLite && threatMetrixProfiling !== 'prequalify' && scriptLoaded ) {
      // eslint-disable-next-line
      console.log(`session:${props.threatMetrixData?.trackingId}`);
      // notify console once the profiling is completed
      (window as any).threatmetrix?.profile(
        props.threatMetrixData?.threatMetrixProfilingDomain,
        props.threatMetrixData?.threatMetrixOrgId,
        props.threatMetrixData?.trackingId
      );
      props.setThreatMetrix('prequalify');
      setThreatMetrixProfiling('prequalify');
    }
  }, [threatMetrixProfiling, scriptLoaded]);

  useEffect(() => {
    if (window.location.href.indexOf(currentPage) === -1) {
      window.history.replaceState(currentPage, 'Title', `/heloc/${isLite ? 'lite/' : ''}${currentPage}${window.location.search}`);
    }
  }, [currentPage]);

  // notify console once the profiling is completed
  (window as any).tmx_profiling_complete = (session_id: any) => {
    console.log(`tmx_profiling_complete completed with session_id ${session_id}`);
  };

  useEffect(() => {
    if (currentPage === 'address' && props.showNameMismatchWarning) {
      handleBack();
    }
  }, [props.showNameMismatchWarning]);

  // Load the TCPA script so that we can verify the consent is captured
  if (!tcpaLoad) {
    loadScriptFunction(
      `https://create.lidstatic.com/campaign/${props.tcpaData.campaignId}.js?snippet_version=2`,
      onTcpaLoad
    );
  }

  useEffect(() => {
    if (militaryServiceStatus !== undefined) {
      handleSubmit();
    }
  }, [militaryServiceStatus]);

  // Used to save data to the personal form
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setHelocForm({
      ...helocForm,
      [name !== '' ? name : event.target.name]: event.target.value,
    });
  };

  // Used to pass the personal data to the orchestrator
  const handleSubmit = async () => {
    setLoading(true);
    setTrySubmit(true);
    if (props?.setShowNameMismatchWarning) {
      props.setShowNameMismatchWarning(false);
    }
    const keys = Object.keys(helocForm).filter((key: string) => !['suffix', 'middleName'].includes(key));
    // Form the tcpa id from the tcpa cookie
    const tcpaAuditId = Cookies.get(
      `leadid_token-${props.tcpaData.accountCode.toUpperCase()}-${props.tcpaData.campaignId.toUpperCase()}`
    );

    let pageData;
    if (currentPage === 'address') {
      setCurrentPage('personal');
      setTrySubmit(false);
    } else if (keys.every((key) => key === 'unitNumber' || helocForm[key as keyof IPersonalForm].length > 0)
      && tcpaAuditId && tcpaAuditId.length > 0
      && (militaryService || (tcpaChecked && electronicChecked))
      && helocForm.phoneNumber.length === 10 && validateEmail(helocForm.email)) {
      // Validate everything has been inputed correctly and that the tcpa id is ready
      pageData = `{ 
          page: "${pageName}"
          ${isLite ? 'lite: true' : ''}
          personalData: {
            firstName: "${helocForm.firstName}"
            middleName: "${helocForm.middleName}"
            lastName: "${helocForm.lastName}"
            email: "${helocForm.email}"
            phoneNumber: "${helocForm.phoneNumber}"
            tcpAuditId: "${tcpaAuditId}"
            address: "${borrowerAddressForm.residenceAddress.address}"
            apt: "${borrowerAddressForm.residenceAddress.unitNumber}"
            city: "${borrowerAddressForm.residenceAddress.city}"
            state: "${borrowerAddressForm.residenceAddress.state}"
            zip: "${borrowerAddressForm.residenceAddress.zip}"
            residenceStartDate: "${
              borrowerAddressForm.residenceStartDate
                ? formatDate(new Date(borrowerAddressForm.residenceStartDate))
                : ''
            }"
            suffix: "${helocForm.suffix || ''}"
            ${isLite ? '' : `isActiveOrExMilitary: ${militaryServiceStatus}`}
          }
        }`;
      await handlePageSubmit(pageData);
      setTrySubmit(false);
    }
    setLoading(false);
  };

  // Used to go back to previous screen (currently property screens)
  const handleBack = async () => {
    if (!isLite && currentPage === 'personal') {
      setCurrentPage('address');
    } else {
      await handleBackSubmit(pageName);
    }
  };

  const hasError = (name: string) => trySubmit && helocForm[name as keyof IPersonalForm].length <= 0;

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    const isMilitaryService = selectedValue === options[0].value;
    setLoading(true);
    setMilitaryServiceStatus(isMilitaryService);
  };

  const backButton = () => {
    setMilitaryService(false);
    setTcpaChecked(!tcpaChecked);
    setElectronicChecked(!electronicChecked);
  };
  if (militaryService) {
    return (
      <Form title={content.military_service_title} progress={progress}>
        {
          loading
            ? (
              <div className="flex items-center justify-center min-h-[420px]">
                <Loader color="#D13239" className="loader-medium" />
              </div>
            )
            : (
              <div>
                <div className="min-h-[420px]">
                  <RadioButtons
                    className="radioButton"
                    radios={options}
                    onChange={handleSelect}
                    updateCheckedValue={updateCheckedValue}
                    isChecked={isChecked}
                    disabled={loading}
                  />
                </div>
                <div className="flex justify-center mt-8 -ml-8 mb-4 xl:block xl:mb-8 xl:ml-0">
                  <Button
                    buttonStyle="quaternary"
                    iconPos="left"
                    iconName="chevron-left-large"
                    onClick={backButton}
                  >Back
                  </Button>
                </div>
              </div>
            )
        }
      </Form>
    );
  }

  return (
    <Form title={content.header} progress={progress}>
      {currentPage === 'address' ? (
        <HelocBorrowerAddress
          content={content.address}
          isDisaster={props.isDisaster}
          propertyType={props.propertyData.propertyType}
          propertyAddressData={props.propertyData.address}
          borrowerAddressForm={borrowerAddressForm}
          setBorrowerAddressForm={setBorrowerAddressForm}
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          loading={loading}
          personalAddressData={props.basicInfoData?.residenceAddress}
        />
      ) : (
        <div>
          <p className="pb-4">{content.sub_header}</p>
          <div className="min-h-[300px]">
            {props.showNameMismatchWarning && (
              <div className="mb-8">
                <Alert text={content.title_mismatch_warning} type="warning" />
              </div>
            )}
            <div className="md:grid md:grid-cols-2 md:space-x-4">
              <TextInput
                name="firstName"
                value={helocForm.firstName}
                label={content.first_name_label}
                onChange={handleChange}
                hasError={hasError('firstName')}
                helperText={hasError('firstName') ? 'required' : undefined}
                required={true}
              />
              <TextInput
                name="middleName"
                value={helocForm.middleName}
                label={content.middle_name_label}
                onChange={handleChange}
              />
            </div>
            <div className="md:grid md:grid-cols-2 md:space-x-4">
              <TextInput
                name="lastName"
                value={helocForm.lastName}
                label={content.last_name_label}
                onChange={handleChange}
                hasError={hasError('lastName')}
                helperText={hasError('lastName') ? 'required' : undefined}
                required={true}
              />
              <TextInput
                name="suffix"
                value={helocForm.suffix}
                label={content.suffix_label}
                onChange={handleChange}
              />
            </div>
            <div className="md:grid md:grid-cols-2 md:space-x-4">
              <TextInput
                name="email"
                value={helocForm.email}
                label={content.email_label}
                onChange={handleChange}
                hasError={trySubmit && !validateEmail(helocForm.email)}
                helperText={trySubmit && !validateEmail(helocForm.email) ? 'required' : undefined}
                required={true}
              />
              <TextInput
                name="phoneNumber"
                value={helocForm.phoneNumber}
                label={content.phone_number_label}
                onChange={(input) => handleChange(input, 'phoneNumber')}
                hasError={trySubmit && helocForm.phoneNumber.length < 10}
                helperText={trySubmit && helocForm.phoneNumber.length < 10 ? 'required' : undefined}
                required={true}
                mask={MASKS.PHONE}
              />
            </div>
            <div className="flex flex-row mb-4">
              <div>
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={electronicChecked}
                  onChange={() => setElectronicChecked(!electronicChecked)}
                />
              </div>
              <div className="text-base text-left">
                {formatRichText('certify', content.certify_label, content)}
              </div>
            </div>
            <div className="flex flex-row mb-4">
              <div>
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={tcpaChecked}
                  onChange={() => setTcpaChecked(!tcpaChecked)}
                />
              </div>
              <div>
                <input type="hidden" id="leadid_tcpa_disclosure" />
                {content.tcpa_disclaimer
                  ?.replaceAll('\n\n', '\n')
                  .split('\n')
                  .map((line: string, index: number) => (
                    <div className="text-base mb-4" key={`disclaimer${index}`}>
                      {formatRichText(`${`tcpa${index}line`}`, line, content)}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-12">
            <Button
              buttonStyle="primary"
              className="!w-full md:!w-64 nextButton"
              onClick={() => (isLite ? handleSubmit() : setMilitaryService(true))}
              loading={loading}
              buttonAttrs={{ disabled: loading || !tcpaChecked || !electronicChecked || !helocForm.firstName?.length
                      || !helocForm.lastName?.length || helocForm.phoneNumber?.length < 10 || !validateEmail(helocForm.email) }}
            >
              I agree and continue
            </Button>
          </div>
          <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
            <Button
              buttonStyle="quaternary"
              iconPos="left"
              iconName="chevron-left-large"
              onClick={handleBack}
              buttonAttrs={{ disabled: loading }}
            >
              Back
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};
