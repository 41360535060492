import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import { useContext, useState } from 'react';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import Form from '../../../components/Form/Form';
import { HelocContext } from '../HelocPage';

export const HelocOptionsPage = () => {
  const { progress, handleBackSubmit, pageName, handlePageSubmit, content } = useContext(HelocContext);
  const [loading, setLoading] = useState(false);
  const options = content.radio_button.map((item: any) => ({
    name: 'options',
    label: item.option.label,
    value: item.option.value,
    active: false
  }));

  const handleSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const pageData = `{ 
      page: "${pageName}"
      ${pageName}: "${event.target.value}"
    }`;
    await handlePageSubmit(pageData);
    setLoading(false);
  };

  return (
    <Form title={content.header} progress={progress}>
      { loading
        ? (
          <div className="min-h-[420px] flex items-center justify-center">
            <Loader color="#D13239" className="loader-medium" />
          </div>
        )
        : (
          <div className="min-h-[420px]">
            <RadioButtons className="radioButton" radios={options} onChange={handleSelect} />
            <div
              className="flex justify-center -ml-8 md:block md:ml-0"
              style={{ marginTop: '12rem' }}
            >
              <Button
                buttonStyle="quaternary"
                iconPos="left"
                iconName="chevron-left-large"
                onClick={() => handleBackSubmit(pageName)}
                buttonAttrs={{ disabled: loading }}
              >Back
              </Button>
            </div>
          </div>
        )}
    </Form>
  );
};
